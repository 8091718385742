import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import FastForwardIcon from "@material-ui/icons/FastForward";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import Slider from "@material-ui/core/Slider";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  controlsWrapper: {
    visibility: "visible",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "100%",
    background: "rgba(0,0,0,0.6)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  button: {
    margin: theme.spacing(1),
  },
  controlIcons: {
    color: "#777",

    fontSize: 50,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#fff",
      transform: "scale(1)",
    },
  },

  bottomIcons: {
    color: "#999",
    "&:hover": {
      color: "#fff",
    },
  },

  volumeSlider: {
    width: 100,
  },

  control_fastforward_btn_f: {
    "&:focus": {
      color: "#fff",
      transform: "scale(1)",
    },
  },

  control_rewind_btn_f: {
    "&:focus": {
      color: "#fff",
      transform: "scale(1)",
    },
  },

  control_play_btn_f: {
    "&:focus": {
      color: "#fff",
      transform: "scale(1)",
    },
  },
}));

const PrettoSlider = withStyles({
  root: {
    height: 8,
    color: "#fff",
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

// function ValueLabelComponent(props) {
//   const { children, open, value } = props;

//   return (
//     <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
//       {children}
//     </Tooltip>
//   );
// }

const Controls = forwardRef(
  (
    {
      onPlayPause,
      playing,
      videoTitle,
      onRewind,
      onFastForward,
      elapsedTime,
      totalDuration,
      played,
    },
    ref
  ) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div ref={ref} className={classes.controlsWrapper}>
        <Grid
          container
          direction="column"
          justify="space-between"
          style={{ flexGrow: 1 }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="center"
          ></Grid>
          {/* bottom controls */}
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ padding: 16 }}
          >
            <Grid item>
              <Grid container alignItems="center">
                <Typography variant="h5" style={{ color: "#fff" }}>
                  {videoTitle}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <Button variant="text">
                <Typography
                  variant="body1"
                  style={{ color: "#fff", marginLeft: 16 }}
                >
                  {elapsedTime}/{totalDuration}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <PrettoSlider
                min={0}
                max={100}
                // ValueLabelComponent={(props) => (
                //   <ValueLabelComponent {...props} value={elapsedTime} />
                // )}
                aria-label="custom thumb label"
                value={played * 100}
              />
            </Grid>

            <Grid
              container
              direction="row"
              alignItems="center"
              justify="center"
            >
              <IconButton
                onClick={onRewind}
                className={[
                  classes.controlIcons,
                  classes.control_rewind_btn_f,
                  "control_rewind_btn",
                ]}
                aria-label="rewind"
              >
                <FastRewindIcon
                  className={classes.controlIcons}
                  fontSize="inherit"
                />
              </IconButton>
              <IconButton
                onClick={onPlayPause}
                className={[
                  classes.controlIcons,
                  classes.control_play_btn_f,
                  "control_play_btn",
                ]}
                aria-label="play"
              >
                {playing ? (
                  <PauseIcon fontSize="inherit" />
                ) : (
                  <PlayArrowIcon fontSize="inherit" />
                )}
              </IconButton>
              <IconButton
                onClick={onFastForward}
                className={[
                  classes.controlIcons,
                  classes.control_fastforward_btn_f,
                  "control_fastforward_btn",
                ]}
                aria-label="forward"
              >
                <FastForwardIcon fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
);

Controls.propTypes = {
  onSeek: PropTypes.func,
  onSeekMouseDown: PropTypes.func,
  onSeekMouseUp: PropTypes.func,
  onDuration: PropTypes.func,
  onRewind: PropTypes.func,
  onPlayPause: PropTypes.func,
  onFastForward: PropTypes.func,
  onVolumeSeekDown: PropTypes.func,
  onChangeDispayFormat: PropTypes.func,
  onPlaybackRateChange: PropTypes.func,
  onToggleFullScreen: PropTypes.func,
  onMute: PropTypes.func,
  playing: PropTypes.bool,
  played: PropTypes.number,
  elapsedTime: PropTypes.string,
  totalDuration: PropTypes.string,
  muted: PropTypes.bool,
  playbackRate: PropTypes.number,
};
export default Controls;
