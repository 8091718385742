import React from "react";
import { Link } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import api from "../../../Environment";
import ToastDemo from "../../Helper/toaster";
import Helper from "../../Helper/helper";
import { translate, t } from "react-multi-lang";

import FilterNoneIcon from "@material-ui/icons/FilterNone";
import VoiceService from "../../Helper/VoiceService";

const DATE_OPTIONS = {
  year: "numeric",
  month: "short",
};

class VideoOverView extends Helper {
  state = {
    inputData: {
      admin_video_id: this.props.videoDetailsFirst.admin_video_id,
    },
    likeApiCall: false,
    dislikeApiCall: false,
    likeReponse: null,
    disLikeReponse: null,
    wishlistApiCall: false,
    wishlistResponse: {
      wishlist_id: null,
    },
    redirect: false,
    redirectPPV: false,
    redirectPaymentOption: false,
    playButtonClicked: false,
    wishlistStatusCheck: 0,
    episodes: false,
    startingV: true,
    handleKeyDown: this.handleKeyDown.bind(this),
  };

  setFocusedItem = (nextItem) => {
    nextItem[0].tabIndex = 0;
    nextItem[0].focus();
    VoiceService(nextItem[0].textContent + " button");
  };
  componentDidMount() {
    this.setState({ playButtonClicked: false });

    let wishlistStatusCheck = 0;
    if (this.props.videoDetailsFirst.wishlist_status == 1) {
      wishlistStatusCheck = 1;
    } else {
      wishlistStatusCheck = 0;
    }
    this.setState({ wishlistStatusCheck });

    const playBtn = document.getElementsByClassName("play_btn");

    playBtn[0].tabIndex = 0;
    playBtn[0].focus();

    window.addEventListener("keydown", this.state.handleKeyDown);

    let videoDetailElement = document.getElementById("video-detail");
    if (videoDetailElement) {
      VoiceService(videoDetailElement.innerText + "; Play Button");
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.state.handleKeyDown);
  }

  handleKeyDown(event) {

    console.log("Keydown Video Details Page");
      if (this.state.startingV === true) {
        if (
          localStorage.getItem("accessToken") &&
          window.location.href.indexOf("details") > -1
        ) {
          if (event.code === "Enter") {
            this.setState({ startingV: false });
          }

          else if (event.code === "ArrowDown") {
            const playBtn = document.getElementsByClassName("play_btn");
            const trailerBtn = document.getElementsByClassName("trailer_btn");
            const seriestBtn = document.getElementsByClassName("series_btn");
            const mylistBtn = document.getElementsByClassName("mylist_btn");

            if (playBtn[0] === document.activeElement) {
              if (trailerBtn.length > 0) {
                this.setFocusedItem(trailerBtn);
                return;
              } else if (seriestBtn.length > 0) {
                this.setFocusedItem(seriestBtn);
                return;
              } else {
                this.setFocusedItem(mylistBtn);
                return;
              }
            } else if (trailerBtn[0] === document.activeElement) {
              if (seriestBtn.length > 0) {
                this.setFocusedItem(seriestBtn);
                return;
              } else {
                this.setFocusedItem(mylistBtn);
                return;
              }
            } else if (seriestBtn[0] === document.activeElement) {
              this.setFocusedItem(mylistBtn);
              return;
            }
          }

          else if (event.code === "ArrowUp") {
            const playBtn = document.getElementsByClassName("play_btn");
            const trailerBtn = document.getElementsByClassName("trailer_btn");
            const seriestBtn = document.getElementsByClassName("series_btn");
            const mylistBtn = document.getElementsByClassName("mylist_btn");

            if (mylistBtn[0] === document.activeElement) {
              if (seriestBtn.length > 0) {
                this.setFocusedItem(seriestBtn);
                return;
              } else if (trailerBtn.length > 0) {
                this.setFocusedItem(trailerBtn);
                return;
              } else {
                this.setFocusedItem(playBtn);
                return;
              }
            } else if (seriestBtn[0] === document.activeElement) {
              if (trailerBtn.length > 0) {
                this.setFocusedItem(trailerBtn);
                return;
              } else {
                this.setFocusedItem(playBtn);
                return;
              }
            } else if (trailerBtn[0] === document.activeElement) {
              this.setFocusedItem(playBtn);
              return;
            }
          }

          else if (event.code === "Backspace") {
            this.setState({ startingV: false });
            VoiceService("");
            window.location = "/";

            //this.props.history.goBack();
          }
        }
      }
    }

  handleOnClickLike = (event) => {
    event.preventDefault();

    api.postMethod("videos/like", this.state.inputData).then((response) => {
      if (response.data.success === true) {
        ToastDemo(this.props.toastManager, "You liked this Video!", "success");
        this.setState({
          likeReponse: response.data.data,
          likeApiCall: true,
        });
      } else {
        ToastDemo(
          this.props.toastManager,
          response.data.error_messages,
          "error"
        );
      }
    });
  };

  handleOnClickDislike = (event) => {
    event.preventDefault();

    api.postMethod("videos/dis_like", this.state.inputData).then((response) => {
      if (response.data.success === true) {
        ToastDemo(
          this.props.toastManager,
          "You Disliked this Video!",
          "success"
        );
        this.setState({
          disLikeReponse: response.data.data,
          dislikeApiCall: true,
        });
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
      }
    });
  };

  handleWishList = (event) => {
    event.preventDefault();

    // this.wishlistUpdate(this.state.inputData);

    api
      .postMethod("wishlists/operations", this.state.inputData)
      .then((response) => {
        if (response.data.success) {
          // ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({ startingV: true });
          this.setState({
            wishlistResponse: response.data,
            wishlistApiCall: true,
          });
          if (response.data.wishlist_id != null) {
            this.setState({
              wishlistStatusCheck: 1,
            });
            VoiceService("Add to watch list");
          } else {
            this.setState({
              wishlistStatusCheck: 0,
            });
            VoiceService("Remove from watch list");
          }
        } else {
          this.setState({ startingV: true });
          // ToastDemo(
          //   this.props.toastManager,
          //   response.data.error_messages,
          //   "error"
          // );
        }
      });
  };

  handleSetStarting = () => {
    this.setState({ startingV: false });
  };

  handlePlayVideo = (event) => {
    VoiceService("");
    event.preventDefault();
    this.redirectStatus(this.props.videoDetailsFirst);
    this.setState({ playButtonClicked: true, startingV: false });
  };

  handlePlayTrailer = (event) => {
    VoiceService("");
    event.preventDefault();
    this.redirectStatus(this.props.videoDetailsFirst);
    this.setState({ playButtonClicked: true });
  };

  getFormatedDuration(duration) {
    const hours = duration.split(":")[0];
    const minutes = duration.split(":")[1];
    const du = hours + " hr " + minutes + " m ";
    return du;
  }

  getTitle(title) {
    return title.replace(/ *\([^)]*\) */g, "");
  }

  getYear(year) {
    let ye = "";
    if (year.includes("(")) {
      const regExp = /\([^)]+\)/;
      ye = regExp.exec(year).toString();
      ye = ye.replace("(", "");
      ye = ye.replace(")", " ");
    }

    return ye;
  }
  render() {
    const {
      videoDetailsFirst,
      trailerDetails,
      trailer,
      genreVideos,
      genres,
      isSeries,
    } = this.props;

    const {
      likeReponse,
      likeApiCall,
      disLikeReponse,
      dislikeApiCall,
      wishlistApiCall,
      wishlistResponse,
      wishlistStatusCheck,
    } = this.state;

    if (this.state.playButtonClicked) {
      const returnToVideo = this.renderRedirectPage(
        this.props.videoDetailsFirst
      );

      if (returnToVideo != null) {
        return returnToVideo;
      }
    }
    return (
      <div className="slider-topbottom-spacing">
        <div className="overview-content">
          {this.state.episodes ? (
            ""
          ) : (
            <div id="video-detail">
              <h1 className="banner_video_title">
                {this.getTitle(videoDetailsFirst.title)}
              </h1>
              <h4 className="banner_video_details">
                <span>{this.getYear(videoDetailsFirst.title)}</span>
                {this.getYear(videoDetailsFirst.title) !== "" ? (
                  <span className="duration_sep"> | </span>
                ) : null}
                <span>{videoDetailsFirst.video_resize_path}</span>
                {videoDetailsFirst.video_resize_path ? (
                  <span className="duration_sep"> | </span>
                ) : null}

                <span>
                  {this.getFormatedDuration(videoDetailsFirst.duration)}
                </span>
              </h4>
              <h4 className="banner_video_details">
                {videoDetailsFirst.should_display_ppv == 1 ? (
                  <span className="red-box">
                    {videoDetailsFirst.currency} {videoDetailsFirst.ppv_amount}
                  </span>
                ) : (
                  ""
                )}
              </h4>
              <h4 className="slider_video_text">
                {videoDetailsFirst.description}
              </h4>
              {videoDetailsFirst.details ? (
                <h4 className="slider_video_text casting video_desials_h4">
                  {" "}
                  <span className="video_desials_lable casting">
                    Starring:{" "}
                  </span>
                  {videoDetailsFirst.details}
                </h4>
              ) : null}
              {videoDetailsFirst.reviews ? (
                <h4 className="slider_video_text  casting video_desials_h4">
                  {" "}
                  <span className="video_desials_lable casting">Writers: </span>
                  {videoDetailsFirst.reviews}
                </h4>
              ) : null}
              {videoDetailsFirst.trailer_resize_path ? (
                <h4 className="slider_video_text  casting video_desials_h4">
                  {" "}
                  <span className="video_desials_lable casting">
                    Director:{" "}
                  </span>
                  {videoDetailsFirst.trailer_resize_path}
                </h4>
              ) : null}
            </div>
          )}
          <div className="banner-btn-sec">
            <Link
              to="#"
              className="btn btn-danger btn-right-space br-0 play_btn"
              onClick={this.handlePlayVideo}
            >
              <i className="fas fa-play mr-2" />
              play
            </Link>

            {videoDetailsFirst.trailer_video !== "" ? (
              <Link
                to={{
                  pathname: `/video/${videoDetailsFirst.admin_video_id}`,
                  state: {
                    videoDetailsFirst: trailer[0],
                    videoFrom: "trailer",
                  },
                }}
                className="btn btn-outline-secondary btn-right-space br-0 trailer_btn"
              >
                trailer
              </Link>
            ) : null}

            {isSeries === 1 ? (
              <Link
                to={{
                  pathname: `/episode/${videoDetailsFirst.admin_video_id}`,
                }}
                className="btn btn-outline-secondary btn-right-space br-0 series_btn"
              >
                <FilterNoneIcon className="mr-2"></FilterNoneIcon>
                Episodes
              </Link>
            ) : null}
            <Link
              to="#"
              onClick={this.handleWishList}
              className="btn btn-outline-secondary btn-right-space mylist_btn"
            >
              {wishlistStatusCheck == 1 ? (
                <div>
                  <i className="" style={{ display: "none" }} />
                  <img
                    src={window.location.origin + "/images/tick.png"}
                    className="mr-2"
                  />
                  Watch List
                </div>
              ) : (
                <div>
                  <i className="" style={{ display: "none" }}></i>
                  <img
                    src={window.location.origin + "/images/add.png"}
                    className="mr-2 "
                  />
                  Watch List
                </div>
              )}
            </Link>
          </div>
        </div>

        <div className="modal fade confirmation-popup" id="spam-popup">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <form>
                <div className="modal-header">
                  <h4 className="modal-title">{t("report_this_video")}</h4>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>

                <div className="modal-body">
                  <p>{t("report_video_note")}</p>

                  <div className="form-check">
                    <input type="radio" id="test1" name="radio-group" checked />
                    <label htmlFor="test1">{t("sexual_content")}</label>
                  </div>
                  <div className="form-check">
                    <input type="radio" id="test2" name="radio-group" />
                    <label htmlFor="test2">{t("violent_repulsive")}</label>
                  </div>
                  <div className="form-check">
                    <input type="radio" id="test3" name="radio-group" />
                    <label htmlFor="test3">{t("hateful_or_abusive")}</label>
                  </div>
                  <div className="form-check">
                    <input type="radio" id="test4" name="radio-group" />
                    <label htmlFor="test4">{t("harmful_act")}</label>
                  </div>
                  <div className="form-check">
                    <input type="radio" id="test5" name="radio-group" />
                    <label htmlFor="test5">{t("child_abuse")}</label>
                  </div>
                  <div className="form-check">
                    <input type="radio" id="test6" name="radio-group" />
                    <label htmlFor="test6">{t("spam_or_misleading")}</label>
                  </div>
                  <div className="form-check">
                    <input type="radio" id="test7" name="radio-group" />
                    <label htmlFor="test7">{t("infringers")}</label>
                  </div>
                  <div className="form-check">
                    <input type="radio" id="test8" name="radio-group" />
                    <label htmlFor="test8">{t("caption_issue")}</label>
                  </div>
                </div>

                <div className="modal-footer">
                  <button type="button" className="btn btn-danger">
                    {t("submit")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(translate(VideoOverView));
