import React, { Component } from "react";
import { Link } from "react-router-dom";

import api from "../../Environment";

import Slider from "../SliderView/MainSlider";
import HomePageBanner from "../NewUI/homePageBanner";
import ContentLoader from "../Static/contentLoader";

import { translate } from "react-multi-lang";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import { Keyboard, Lazy } from "swiper";
import SideNav from "react-simple-sidenav";

import { ShimmerSimpleGallery } from "react-shimmer-effects";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SearchIcon from "@material-ui/icons/Search";
import VoiceService from "../Helper/VoiceService";
import { LibraryBooks, VerifiedUser } from "@material-ui/icons";

const inputData = {
  page_type: "HOME",
  skip: 0,
  take: 5,
};

const homeClassName = {
  playTrailer: "play_trailer_btn",
  moreInfo: "more_info_btn",
  colMd1: "col-md-1",
  colMd2: "col-md-2",
  colMd10: "col-md-10",
  colMd11: "col-md-11",
  navBarProfile: "navBar_profile",
  navBarLogo: "navBar_logo",
  navBarText: "navBar_text",
  navBarSearch: "navBar_search",
  searchText: "search_text",
  navBarExit: "navBar_exit",
  exitText: "exit_text",
  navBarPrivacy: "navBar_privacy",
  privacyText: "privacy_text",
  privacyIcon: "privacy_icon",
  navBarTerms: "navBar_terms",
  termsText: "terms_text",
  termsIcon: "terms_icon",
  inactiveColor: "inactive-color",
  whiteText: "white-text",
  whiteBorder: "white_border",
  searchIcon: "search_icon",
  exitIcon: "exit_icon",
}

const elementKey = {
  PROFILE: "profile",
  SEARCH: "search",
  EXIT: "exit",
  PRIVACY: "privacy",
  TERMS: "terms",
  PLAY_TRAILER: "playerTrailer",
  MORE_INFO: "more_info"
}

class Home extends Component {
  state = {
    maindata: null,
    errorHandle: 0,
    loading: true,
    banner: null,
    loadingHomeSecondSection: true,
    loadingSeriesSection: true,
    loadingMoreSecondSection: false,
    hasMoreData: true,
    homeSecondData: null,
    seriesData: null,
    showNav: false,
    activeProfile: null,
    swiper: null,
    swiperV: null,
    swiperArray: [],
    startingH: true,
    isFirstCategory: true,
    isFirstContent: true,
    isFirstRow: true,
    isFirstMovie: true,
    handleKeyDown: this.handleKeyDown.bind(this),
  };

  setFocusedItem = (className) => {
    let element = document.getElementsByClassName(className);
    if (element[0]) {
      element[0].tabIndex = 0;
      element[0].focus();
      VoiceService(element[0].textContent);
    }
  };

  componentDidMount() {
    window.addEventListener("keydown", this.state.handleKeyDown);
    api
      .postMethod("home_first_section_vizio", inputData)
      .then((response) => {
        if (response.data.success === true) {
          let maindata = response.data.data;
          let banner = response.data.banner;

          this.setState({
            loading: false,
            maindata: maindata,
            banner: banner,
          });

          if (banner && banner.data && banner.data.length > 0) {
            setTimeout(() => {
              VoiceService(banner.data[0].title + "; " + banner.data[0].description);
            }, 1500);
          }
        } else {
          let errorHandle = 1;
          this.setState({ errorHandle });
        }
      })
      .catch(function (error) { });

    this.loadSeriasData(inputData);
  }
  componentWillUnmount() {
    window.removeEventListener("keydown", this.state.handleKeyDown);
  }

  handleKeyDown(event) {
    console.log("Keydown Home Page");
    if (
      localStorage.getItem("accessToken") &&
      (window.location.pathname === "/" ||
        window.location.href.indexOf("home") > -1)
    ) {

      if (this.state.startingH === true) {
        if (document.activeElement.classList.contains(homeClassName.playTrailer) ||
          document.activeElement.classList.contains(homeClassName.moreInfo)) {

          if (document.activeElement.classList.contains(homeClassName.playTrailer)) {
            if (event.code === "ArrowRight") {
              this.blurElement(elementKey.PLAY_TRAILER);
              this.focusElement(elementKey.MORE_INFO);
            } else if (event.code === "ArrowLeft") {
              this.blurElement(elementKey.PLAY_TRAILER);
              this.openSideBar();
            }
          } else {
            if (event.code === "ArrowLeft") {
              this.blurElement(elementKey.MORE_INFO);
              this.focusElement(elementKey.PLAY_TRAILER);
            }

          }

          if (event.code === "ArrowDown") {
            document.activeElement.classList.remove(homeClassName.whiteBorder);
            document.activeElement.blur();
            this.enableSwiper();
          }
          return;
        }
        if (event.code === "Enter" && !this.state.showNav && this.state.swiperV && this.state.swiperArray) {
          this.setState({ startingH: false });
          let m = this.state.swiperV;
          let n = this.state.swiperArray;
          const item =
            n[m.activeIndex]["slides"][n[m.activeIndex].activeIndex];
          let moviePath =
            item.getElementsByClassName("item_focus item5")[0];

          if (!moviePath) {
            moviePath =
              item.getElementsByClassName("view_all_item")[0];
          }

          if (moviePath) {
            moviePath.click();
          }
        }

        if (event.code === "ArrowDown") {
          if (this.state.showNav) {
            if (document.activeElement.classList.contains(homeClassName.navBarProfile)) {
              this.blurElement(elementKey.PROFILE);
              this.focusElement(elementKey.SEARCH);
            } else if (document.activeElement.classList.contains(homeClassName.navBarSearch)) {
              this.blurElement(elementKey.SEARCH);
              this.focusElement(elementKey.EXIT);
            }else if (document.activeElement.classList.contains(homeClassName.navBarExit)) {
              this.blurElement(elementKey.EXIT);
              this.focusElement(elementKey.PRIVACY);
            }else if (document.activeElement.classList.contains(homeClassName.navBarPrivacy)) {
              this.blurElement(elementKey.PRIVACY);
              this.focusElement(elementKey.TERMS);
            }
          }
        }

        if (event.code === "ArrowUp") {
          if (this.state.showNav) {
            if (document.activeElement.classList.contains(homeClassName.navBarSearch)) {
              this.blurElement(elementKey.SEARCH);
              this.focusElement(elementKey.PROFILE);
            } else if (document.activeElement.classList.contains(homeClassName.navBarExit)) {
              this.blurElement(elementKey.EXIT);
              this.focusElement(elementKey.SEARCH);
            } else if (document.activeElement.classList.contains(homeClassName.navBarPrivacy)) {
              this.blurElement(elementKey.PRIVACY);
              this.focusElement(elementKey.EXIT);
            } else if (document.activeElement.classList.contains(homeClassName.navBarTerms)) {
              this.blurElement(elementKey.TERMS);
              this.focusElement(elementKey.PRIVACY);
            }
          } else if (this.state.isFirstCategory) {
            if (this.state.isFirstRow) {
              this.focusElement(elementKey.PLAY_TRAILER);
              this.disableSwiper();
            } else {
              this.state.isFirstRow = true;
            }
          }
        }
      }

      if (event.code === "ArrowLeft" && this.state.isFirstContent && !this.state.showNav) {
        if (this.state.isFirstMovie) {
          this.openSideBar();
        } else {
          this.state.isFirstMovie = true;
        }
      } else if (event.code === "ArrowRight" && this.state.showNav) {
        this.closeSideBar();
        this.focusElement(elementKey.PLAY_TRAILER);
        this.disableSwiper();
      }
    }
  }

  disableSwiper() {
    let swiperV = this.state.swiperV;
    if (swiperV === null) return;
    if (swiperV.destroyed) return;
    if (typeof swiperV === "undefined") return;
    if (swiperV.keyboard) {
      swiperV.keyboard.disable();
    }

    let swipers = this.state.swiperArray;
    if (swipers.length === 0) return;
    for (var i = 0; i < swipers.length; i++) {
      swipers[i].keyboard.disable();
    }

  }

  openSideBar() {
    const sideBar = document.getElementsByClassName("sideBar");
    if (sideBar[0]) {
      sideBar[0].classList.add(homeClassName.colMd2);
      sideBar[0].classList.remove(homeClassName.colMd1);
    }

    const mainContent = document.getElementsByClassName("mainContent");
    if (mainContent[0]) {
      mainContent[0].classList.add(homeClassName.colMd10);
      mainContent[0].classList.remove(homeClassName.colMd11);
    }

    this.setState({ showNav: true });
    this.focusElement(elementKey.PROFILE);

    this.showElement(homeClassName.navBarText);
    this.showElement(homeClassName.searchText);
    this.showElement(homeClassName.exitText);
    this.showElement(homeClassName.privacyText);
    this.showElement(homeClassName.termsText);

    this.disableSwiper();

    VoiceService(localStorage.getItem("active_profile_name"));
  }

  closeSideBar() {
    const sideBar = document.getElementsByClassName("sideBar");
    if (sideBar[0]) {
      sideBar[0].classList.add(homeClassName.colMd1);
      sideBar[0].classList.remove(homeClassName.colMd2);
    }

    const mainContent = document.getElementsByClassName("mainContent");
    if (mainContent[0]) {
      mainContent[0].classList.add(homeClassName.colMd11);
      mainContent[0].classList.remove(homeClassName.colMd10);
    }

    this.setState({ showNav: false });
    this.blurElement(elementKey.PROFILE);
    this.blurElement(elementKey.SEARCH);
    this.blurElement(elementKey.EXIT);
    this.blurElement(elementKey.PRIVACY);
    this.blurElement(elementKey.TERMS);

    this.hideElement(homeClassName.navBarText);
    this.hideElement(homeClassName.searchText);
    this.hideElement(homeClassName.exitText);
    this.hideElement(homeClassName.privacyText);
    this.hideElement(homeClassName.termsText);
  }

  enableSwiper() {
    let swiperV = this.state.swiperV;
    if (swiperV === null) return;
    if (swiperV.destroyed) return;
    if (typeof swiperV === "undefined") return;
    if (swiperV.keyboard) {
      swiperV.keyboard.enable();
    }

    let swipers = this.state.swiperArray;
    if (swipers.length === 0) return;
    for (var y = 0; y < swipers.length; y++) {
      swipers[y].keyboard.enable();
    }
  }

  addActiveTextClass(className) {
    let element = document.getElementsByClassName(className);
    if (element[0]) {
      element[0].classList.replace(homeClassName.inactiveColor, homeClassName.whiteText);
    }
  }

  removeActiveTextClass(className) {
    let element = document.getElementsByClassName(className);
    if (element[0]) {
      element[0].classList.replace(homeClassName.whiteText, homeClassName.inactiveColor);
    }
  }

  addBorder(className) {
    let element = document.getElementsByClassName(className);
    if (element[0]) {
      element[0].classList.add(homeClassName.whiteBorder);
    }
  }

  removeBorder(className) {
    let element = document.getElementsByClassName(className);
    if (element[0]) {
      element[0].classList.remove(homeClassName.whiteBorder);
    }
  }

  showElement(className) {
    let element = document.getElementsByClassName(className);
    if (element[0]) {
      element[0].classList.remove("hidden");
    }
  }

  hideElement(className) {
    let element = document.getElementsByClassName(className);
    if (element[0]) {
      element[0].classList.add("hidden");
    }
  }

  focusElement(key) {
    switch (key) {
      case elementKey.PROFILE:
        this.setFocusedItem(homeClassName.navBarProfile);
        this.addActiveTextClass(homeClassName.navBarText);
        this.addBorder(homeClassName.navBarLogo);
        break;
      case elementKey.SEARCH:
        this.addActiveTextClass(homeClassName.searchIcon);
        this.addActiveTextClass(homeClassName.searchText);
        this.setFocusedItem(homeClassName.navBarSearch);
        this.addBorder(homeClassName.navBarSearch);
        break;
      case elementKey.EXIT:
        this.addActiveTextClass(homeClassName.exitIcon);
        this.addActiveTextClass(homeClassName.exitText);
        this.setFocusedItem(homeClassName.navBarExit);
        this.addBorder(homeClassName.navBarExit);
        break;
      case elementKey.PRIVACY:
        this.addActiveTextClass(homeClassName.privacyIcon);
        this.addActiveTextClass(homeClassName.privacyText);
        this.setFocusedItem(homeClassName.navBarPrivacy);
        this.addBorder(homeClassName.navBarPrivacy);
        break;
      case elementKey.TERMS:
        this.addActiveTextClass(homeClassName.termsIcon);
        this.addActiveTextClass(homeClassName.termsText);
        this.setFocusedItem(homeClassName.navBarTerms);
        this.addBorder(homeClassName.navBarTerms);
        break;
      case elementKey.PLAY_TRAILER:
        this.addActiveTextClass(homeClassName.playTrailer);
        this.setFocusedItem(homeClassName.playTrailer);
        this.addBorder(homeClassName.playTrailer);
        break;
      case elementKey.MORE_INFO:
        this.addActiveTextClass(homeClassName.moreInfo);
        this.setFocusedItem(homeClassName.moreInfo);
        this.addBorder(homeClassName.moreInfo);
        break;
    }
  }

  blurElement(key) {
    switch (key) {
      case elementKey.PROFILE:
        this.removeActiveTextClass(homeClassName.navBarText);
        this.removeBorder(homeClassName.navBarLogo);
        break;
      case elementKey.SEARCH:
        this.removeActiveTextClass(homeClassName.searchIcon);
        this.removeActiveTextClass(homeClassName.searchText);
        this.removeBorder(homeClassName.navBarSearch);
        break;
      case elementKey.EXIT:
        this.removeActiveTextClass(homeClassName.exitIcon);
        this.removeActiveTextClass(homeClassName.exitText);
        this.removeBorder(homeClassName.navBarExit);
        break;
      case elementKey.PRIVACY:
        this.removeActiveTextClass(homeClassName.privacyIcon);
        this.removeActiveTextClass(homeClassName.privacyText);
        this.removeBorder(homeClassName.navBarPrivacy);
        break;
      case elementKey.TERMS:
        this.removeActiveTextClass(homeClassName.termsIcon);
        this.removeActiveTextClass(homeClassName.termsText);
        this.removeBorder(homeClassName.navBarTerms);
        break;
      case elementKey.PLAY_TRAILER:
        this.removeActiveTextClass(homeClassName.playTrailer);
        this.removeBorder(homeClassName.playTrailer);
        break;
      case elementKey.MORE_INFO:
        this.removeActiveTextClass(homeClassName.moreInfo);
        this.removeBorder(homeClassName.moreInfo);
        break;
    }

  }

  async loadSeriasData(inputData) {
    await api
      .postMethod("home_series_section_vizio", inputData)
      .then((response) => {
        if (response.data.success === true) {
          // console.log("Dandash: ", response.data.Sub_Categories)
          this.setState({
            loadingSeriesSection: false,
            seriesData: response.data.data,
            // seriesData: response.data.Sub_Categories,
          });
        } else {
          let errorHandle = 1;
          this.setState({ errorHandle });
        }
      })
      .catch(function (error) { });
    this.loadSecondData(inputData);
    // End
  }

  async loadSecondData(inputData) {
    let firstData = this.state.maindata;
    let seriesData = this.state.seriesData;
    let homeSecondData = this.state.homeSecondData;
    let secondData = [];
    if (homeSecondData && homeSecondData.length > 0) {
      secondData = homeSecondData;
    } else if (firstData && secondData && firstData.length > 0 && seriesData.length > 0) {
      secondData = firstData.concat(seriesData);
      let ttsText = firstData[0].title + "; ";
      if (firstData[0].data.length > 0) {
        ttsText += firstData[0].data[0].title;
      }

      VoiceService(ttsText);
    }

    await api
      .postMethod("home_second_section_new_vizio", inputData)
      .then((response) => {
        if (response.data.success === true) {
          const data = response.data.data
          this.setState({
            loadingHomeSecondSection: false,
            loadingMoreSecondSection: false,
            homeSecondData:
              secondData.length > 0
                ? secondData.concat(data)
                : data,
            homeData: response.data.Sub_Categories,
            hasMoreData: data.length >= inputData.take
          });
        } else {
          let errorHandle = 1;
          this.setState({ errorHandle });
        }
      })
      .catch(function (error) { });

    // this.loadSeriasData(inputData)
    // End
  }



  loadMoreSecondData() {
    inputData.skip += 5;
    this.setState({
      loadingMoreSecondSection: true,
    })
    this.loadSecondData(inputData, this.state.homeSecondData)
  }

  getAriaLabelFromItem(swiper, isPreviousCheck) {
    if (swiper && !(isPreviousCheck && swiper.realIndex == swiper.previousIndex) && swiper.realIndex >= 0 && swiper.wrapperEl.children.length > swiper.realIndex) {
      return swiper.wrapperEl.children[swiper.realIndex].firstChild.ariaLabel;
    }

    return "";
  }

  onSlideChange(swiperCore) {
    const {
      activeIndex,
      snapIndex,
      previousIndex,
      realIndex,
    } = swiperCore;
    console.log({ activeIndex, snapIndex, previousIndex, realIndex });
    if (activeIndex - this.state.maindata.length - this.state.seriesData.length > inputData.skip && this.state.hasMoreData) {
      this.loadMoreSecondData();
    }

    this.setState({
      isFirstCategory: realIndex === 0,
      isFirstRow: realIndex > 0 ? false : this.state.isFirstRow
    });

    var ttsText = "";
    const swiperElement = swiperCore.wrapperEl.children[realIndex].children[0];
    ttsText = swiperElement.textContent + "; ";
    const swiperItem = swiperElement.children[1].swiper;
    ttsText += this.getAriaLabelFromItem(swiperItem, false);

    VoiceService(ttsText);
  }

  getSwiperArray(swiper) {
    let array = this.state.swiperArray;
    array.push(swiper);
    this.setState({ swiperArray: array });
  }

  renderViewAllSwiper(maindata) {
    return (<>
      <SwiperSlide>

        <Link
          to={{
            pathname: "/view-all",
            state: {
              url_type: maindata.url_type,
              url_type_id: maindata.url_type_id,
              page_type: "HOME",
              title: maindata.title,
            },
          }}
          aria-label="View All"
        >
          <div className="view_all_item">
            <span className="view_all_content">View All</span></div>
        </Link>
      </SwiperSlide>
      <SwiperSlide />
      <SwiperSlide />
      <SwiperSlide />
    </>);
  }

  renderVideoList = (maindata, index) => {
    return (
      <React.Fragment key={index}>
        <div className={"main-slidersec" + index}>
          <h3 className="">
            {maindata.title}
            <i className="fas fa-angle-right ml-2" />
          </h3>
          <Swiper
            spaceBetween={0}
            slidesPerView={4}
            loop={false}
            lazy={true}
            keyboard={{
              enabled: true,
              onlyInViewport: true,
            }}
            modules={[Keyboard]}
            preloadImages={true}
            // onClick={(swiper) => alert(swiper.activeIndex)}
            onSlideChange={(swiper) => {
              this.setState({
                swiper: swiper,
                isFirstContent: swiper.realIndex === 0,
                isFirstMovie: swiper.realIndex > 0 ? false : this.state.isFirstMovie
              });
              if (swiper.realIndex != swiper.previousIndex && swiper.realIndex >= 0 && swiper.wrapperEl.children.length > swiper.realIndex) {
                VoiceService(this.getAriaLabelFromItem(swiper, true));
              }
            }
            }
            // onSwiper={(swiper) => console.log(swiper.activeIndex)}
            onSwiper={
              (swiper) => {
                this.getSwiperArray(swiper);
                // console.log("onSwiper")
              }
              // swiper.activeIndex ? swiper.keyboard.enabled = false : swiper.keyboard.enabled = true
            }
          >
            {maindata.data.map((movie) => (
              <SwiperSlide key={movie.admin_video_id}>
                <Link
                  to={"/details/" + movie.admin_video_id}
                  className="item_focus item5"
                  aria-label={movie.title}
                >
                  <img
                    className="item  item5 sliderthumb-text swiper-lazy"
                    alt=""
                    src={movie.default_image}
                    loading="lazy"
                  />
                </Link>
              </SwiperSlide>
            ))}
            {this.renderViewAllSwiper(maindata)}
          </Swiper>
        </div>
      </React.Fragment>
    );
  };

  render() {

    console.log("load Home");
    const { t } = this.props;

    const {
      loading,
      maindata,
      banner,
      loadingHomeSecondSection,
      loadingSeriesSection,
      homeSecondData,
      seriesData,
    } = this.state;

    return (
      <div className="main-sec-content noNavBar container-fluid">
        <div class="row">
          <div className="sideBar col-md-1">
            <Link
              className="navBar_profile"
              key={localStorage.getItem("userId")}
              to="/view-profiles"
            >
              <div className="display-navBar">
                <div className="left-sec">
                  <img
                    className="navBar_logo"
                    src={localStorage.getItem("active_profile_image")}
                    alt="profile_img"
                    loading="lazy"
                  />
                </div>
                <div className="right-name navBar_text hidden inactive-color">
                  {localStorage.getItem("active_profile_name")}
                </div>
              </div>
            </Link>
            <Link className="navBar_search new-sidebar-content row" to={{ pathname: "/search" }}>
              {/* <i className="search_icon fas fa-search ml-2" /> */}
              <SearchIcon className="search_icon inactive-color" fontSize="inharit" style={{marginTop: 10}}></SearchIcon>
              <h3 className="search_text  new-sidebar-content hidden inactive-color">Search</h3>
            </Link>
            <Link className="navBar_exit new-sidebar-content row" to={{ pathname: "/logout" }}>
              {/* <i className=" exit_icon fa fa-sign-out ml-2" aria-hidden="true" /> */}
              <ExitToAppIcon className="exit_icon inactive-color" fontSize="fontSizeLarge" style={{marginTop: 10}}></ExitToAppIcon>
              <h3 className="exit_text  new-sidebar-content hidden inactive-color">Exit</h3>
            </Link>
            <Link className="navBar_privacy new-sidebar-content row" to={{ pathname: "/privacy" }}>
              <VerifiedUser className="privacy_icon inactive-color" fontSize="fontSizeLarge" style={{marginTop: 10}}></VerifiedUser>
              <h3 className="privacy_text  new-sidebar-content hidden inactive-color">Privacy</h3>
            </Link>
            <Link className="navBar_terms new-sidebar-content row" to={{ pathname: "/terms" }}>
              <LibraryBooks className="terms_icon inactive-color" fontSize="fontSizeLarge" style={{marginTop: 10}}></LibraryBooks>
              <h3 className="terms_text  new-sidebar-content hidden inactive-color">Terms</h3>
            </Link>
          </div>
          <div className="mainContent col-md-11">
            {loading ? <ContentLoader /> : <HomePageBanner banner={banner} />}
            <div className="main swiper_section">
              {loadingHomeSecondSection ? (
                !loading ? (
                  <ShimmerSimpleGallery card imageHeight={200} rows={1} col={4} />
                ) : (
                  <ContentLoader />
                )
              ) : (
                <Swiper
                  direction={"vertical"}
                  spaceBetween={0}
                  slidesPerView={1}
                  height={
                    Math.max(
                      document.documentElement.clientHeight,
                      window.innerHeight || 0
                    ) / 2.8
                  }
                  loop={false}
                  lazy={true}
                  keyboard={{
                    enabled: true,
                    onlyInViewport: true,
                  }}
                  modules={[Keyboard]}
                  preloadImages={true}
                  onSlideChange={(swiper) => {
                    this.setState({ swiperV: swiper });
                    this.onSlideChange(swiper);
                  }}
                  onSwiper={(swiper) => {
                    this.setState({ swiperV: swiper });
                  }}
                >
                  {/* {alert( homeSecondData.length)} */}
                  {homeSecondData.map((mainDa, index) =>
                    mainDa.data.length === 0 ? (
                      ""
                    ) : loading ? (
                      ""
                    ) : (
                      <SwiperSlide className="v-slider" key={index}>
                        {this.renderVideoList(mainDa, index)}
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              )}

              <div className="height-100" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate(Home);
