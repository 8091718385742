import React from "react";

import { Link } from "react-router-dom";
import Helper from "../../Helper/helper";
import ContentLoader from "../../Static/contentLoader";
import ProgressiveImage from "react-progressive-image";

import { translate } from "react-multi-lang";
import configuration from "react-global-configuration";
import VoiceService from "../../Helper/VoiceService";

class ViewProfilesComponent extends Helper {
  state = {
    loading: true,
    activeProfile: null,
    setFocuse: true,
    handleKeyDown: this.handleKeyDown.bind(this),
  };
  componentDidMount() {
    // view all sub profile

    // let profile1 = document.getElementsByClassName("profile_view_0");
    //     console.log(profile1);
    //     profile1[0].tabIndex = 0;
    //     profile1[0].focus();

    let ttsText = document.querySelector(".view-profiles-head").textContent;
    VoiceService(ttsText);
    this.viewProfiles();

    window.addEventListener("keydown", this.state.handleKeyDown);

    // this.viewProfiles();
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.state.handleKeyDown);
  }

  handleKeyDown(event) {

    console.log("Keydown View Profiles Page");
      if (window.location.href.indexOf("view-profiles") > -1) {
        if (this.state.setFocuse) {
          let profile1 = document.getElementsByClassName("profile_view_0");
          profile1[0].tabIndex = 0;
          profile1[0].focus();
          this.setState({ setFocuse: false });
        }
        if (event.code === "Backspace") {
          // this.props.history.goBack();
          this.props.history.push("/logout");
        }

        if (event.code === "ArrowRight") {
          let profile1 = document.getElementsByClassName("profile_view_0");
          let profile2 = document.getElementsByClassName("profile_view_1");
          let profile3 = document.getElementsByClassName("profile_view_2");
          let profile4 = document.getElementsByClassName("profile_view_3");

          if (profile1[0] === document.activeElement && profile2.length > 0) {
            this.setFocusedItem(profile1, profile2);
          } else if (
            profile2[0] === document.activeElement &&
            profile3.length > 0
          ) {
            this.setFocusedItem(profile2, profile3);
          } else if (
            profile3[0] === document.activeElement &&
            profile4.length > 0
          ) {
            this.setFocusedItem(profile3, profile4);
          }
        } else if (event.code === "ArrowLeft") {
          let profile1 = document.getElementsByClassName("profile_view_0");
          let profile2 = document.getElementsByClassName("profile_view_1");
          let profile3 = document.getElementsByClassName("profile_view_2");
          let profile4 = document.getElementsByClassName("profile_view_3");

          if (profile4[0] === document.activeElement) {
            this.setFocusedItem(profile4, profile3);
          } else if (profile3[0] === document.activeElement) {
            this.setFocusedItem(profile3, profile2);
          } else if (profile2[0] === document.activeElement) {
            this.setFocusedItem(profile2, profile1);
          }
        }
      }
    }

  setFocusedItem = (prevItem, nextItem) => {
    nextItem[0].tabIndex = 0;
    nextItem[0].focus();
    // remove
    let focusImgRemove = prevItem[0].children[0];
    focusImgRemove.classList.remove("white_border");
    // add
    let focusImg = nextItem[0].children[0];
    focusImg.classList.add("white_border");
  };

  handleClick = (profile, event) => {
    event.preventDefault();

    localStorage.setItem("active_profile_id", profile.sub_profile_id);
    localStorage.setItem("active_profile_image", profile.picture);
    localStorage.setItem("active_profile_name", profile.name);
  };

  renderProfile = (activeProfile) => {
    // let renderActiveProfile;
    return (
      <div>
        {activeProfile.map((detail, index) => (
          <li
            className="profile"
            key={detail.id}
            onClick={(event) => this.handleClick(detail, event)}
          >
            <Link
              to="/home"
              className={"profile_view_" + index}
              ref={(input) => {
                this.nameInput = input;
              }}
            >
              <ProgressiveImage
                src={detail.picture}
                placeholder="../assets/img/placeholder.gif"
              >
                {(src, loading) => (
                  <img
                    src={src}
                    style={{ opacity: loading ? 0.5 : 1 }}
                    className={
                      index === 0 ? "profile-img white_border" : "profile-img "
                    }
                    alt="profile_img"
                  />
                )}
              </ProgressiveImage>

              <p className="profile-name">{detail.name}</p>
            </Link>
          </li>
        ))}
      </div>
    );
  };

  render() {
    const { t } = this.props;

    var bgImg = {
      backgroundImage: `url(${configuration.get(
        "configData.common_bg_image"
      )})`,
    };
    const { loading, activeProfile, setFocuse } = this.state;

    if (activeProfile && activeProfile.length > 0 && setFocuse) {
      VoiceService(activeProfile[0].name);
    }

    return (
      <div className="vizio_profile_view">
        <div className="common-bg-img" style={bgImg}>
          <div className="account-page-header">
            <Link to="/home">
              <img
                src={configuration.get("configData.site_logo")}
                className="logo-img"
                alt="logo_img"
              />
            </Link>
          </div>

          <div className="main">
            <div className="view-profile">
              <div className="view-profile-content">
                <div className="head-section">
                  <h1 className="view-profiles-head">{t("who_is_watching")}</h1>
                </div>
                <ul className="choose-profile">
                  {loading ? (
                    <ContentLoader />
                  ) : (
                    this.renderProfile(activeProfile)
                  )}
                </ul>
                {/* <div>
                                    <Link
                                        to="/manage-profiles"
                                        className="grey-outline-btn"
                                    >
                                        {t("manage")} {t("profiles")}
                                    </Link>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate(ViewProfilesComponent);
