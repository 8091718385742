import React, { Component } from "react";

import Slider from "../SliderView/MainSlider";

import api from "../../Environment";
import ContentLoader from "../Static/contentLoader";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Focusable } from "react-js-spatial-navigation";
import { GridContext } from "react-grid-navigator";
import VoiceService from "../Helper/VoiceService";

class ViewAll extends Component {
  state = {
    videoList: null,
    loading: true,
    handleKeyDown: this.handleKeyDown.bind(this),
  };

  
  componentDidMount() {
    if (this.props.location.state) {
      //
    } else {
      window.location = "/home";
    }
    let inputData;
    let apiURL;
    if (this.props.location.state.videoType != undefined) {
      inputData = {
        skip: 0,
        cast_crew_id: this.props.location.state.cast_crew_id,
      };
      apiURL = "v4/cast_crews/videos";
    } else {
      inputData = {
        skip: 0,
        url_type: this.props.location.state.url_type,
        url_type_id: this.props.location.state.url_type_id,
        page_type: this.props.location.state.page_type,
        category_id: this.props.location.state.category_id,
        sub_category_id: this.props.location.state.sub_category_id,
      };
      apiURL = "see_all";
    }
    this.viewAllApiCall(inputData, apiURL);
    window.addEventListener("keydown", this.state.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.state.handleKeyDown);
  }

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    let inputData;
    let apiURL;
    if (nextProps.location.state.videoType != undefined) {
      inputData = {
        skip: 0,
        cast_crew_id: nextProps.location.state.cast_crew_id,
      };
      apiURL = "v4/cast_crews/videos";
    } else {
      inputData = {
        skip: 0,
        url_type: nextProps.location.state.url_type,
        url_type_id: nextProps.location.state.url_type_id,
        page_type: nextProps.location.state.page_type,
        category_id: nextProps.location.state.category_id,
        sub_category_id: nextProps.location.state.sub_category_id,
      };
      apiURL = "see_all";
    }
    this.viewAllApiCall(inputData, apiURL);
  }

  viewAllApiCall = (inputData, apiURL) => {
    api
      .postMethod(apiURL, inputData)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            loading: false,
            videoList: response.data.data,
          });
        } else {
        }
      })
      .catch(function (error) {});
  };

  chunkArray(myArray, chunk_size) {
    let results = [];

    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }

    return results;
  }

  setFocusedItem = (nextItem) => {
    nextItem.tabIndex = 0;
    nextItem.focus();
    VoiceService(nextItem.ariaLabel);
  }

  focusOnFirstItem = () => {
    if (document.activeElement.tagName.toLocaleLowerCase() === "body") {
      this.setFocusedItem(document.getElementsByClassName("view_all_col")[0]);
    }
  }
  handleKeyDown(event) {
    
    console.log("Keydown View All Page");
    const currentEl = document.activeElement;
    const elRect = currentEl.getBoundingClientRect();
    const allItems = document.getElementsByClassName("view_all_col");
    switch (event.code) {
      case "Backspace":
        this.props.history.goBack();
        break;
      case "ArrowUp":
        
        if (allItems) {
          for(let i = 0; i < allItems.length; i++) {
            if (currentEl !== allItems[i]) {
              const itemRect = allItems[i].getBoundingClientRect();
              if ((elRect.x + elRect.width / 2) > itemRect.x && (elRect.x - elRect.width / 2) < itemRect.x
                && (elRect.y - elRect.height / 2) > itemRect.y && (elRect.y - elRect.height / 2) < (itemRect.y + elRect.height)) {
              this.setFocusedItem(allItems[i]);
                break;
              }
            }
          }
        }
          break;

      case "ArrowDown":
        if (allItems) {
          for(let i = 0; i < allItems.length; i++) {
            if (currentEl !== allItems[i]) {
              const itemRect = allItems[i].getBoundingClientRect();
              if ((elRect.x + elRect.width / 2) > itemRect.x && (elRect.x - elRect.width / 2) < itemRect.x
                && (elRect.y + elRect.height / 2) < itemRect.y && (elRect.y + elRect.height / 2) > (itemRect.y - elRect.height)) {
                this.setFocusedItem(allItems[i]);
                break;
              }
            }
          }
        }
          break;

      case "ArrowLeft":
        console.log("Left");
        if (allItems) {
          for(let i = 0; i < allItems.length; i++) {
            if (currentEl !== allItems[i]) {
              const itemRect = allItems[i].getBoundingClientRect();
              if ((elRect.x- elRect.width / 2) > itemRect.x && (elRect.x- elRect.width / 2) < (itemRect.x + elRect.width)
              && (elRect.y - elRect.height / 2) < itemRect.y && (elRect.y + elRect.height / 2) > itemRect.y) {
                this.setFocusedItem(allItems[i]);
                break;
              }
            }
          }
        }
          break;

      case "ArrowRight":
        console.log("Right");
        if (allItems) {
          for(let i = 0; i < allItems.length; i++) {
            if (currentEl !== allItems[i]) {
              const itemRect = allItems[i].getBoundingClientRect();
              if (elRect.x < itemRect.x && (elRect.y - elRect.height / 2) < itemRect.y && (elRect.y + elRect.height / 2) > itemRect.y) {
                this.setFocusedItem(allItems[i]);
                break;
              }
            }
          }
        }
          break;
          case "Enter":
            document.activeElement.parentElement.click();
            break;
    }
  }

  render() {
    // Usage

    let result = null;
    let isFirstItem = true;

    // Split in group of 3 items
    if (this.state.loading) {
      return <ContentLoader />;
    } else {
      result = this.chunkArray(this.state.videoList, 5);
    }

    // Outputs : [ [1,2,3] , [4,5,6] ,[7,8] ]

    return (
      <div className="main p-40">
        <div className="main-slidersec">
          <h3 className="">
            {this.props.location.state.title}
            <i className="fas fa-angle-right ml-2" />
          </h3>
          {/* {result.map((res) => (
            <Slider key={res.index}>
              {res.map((movie) => (
                <Slider.Item movie={movie} key={movie.admin_video_id}>
                  item1
                </Slider.Item>
              ))}
            </Slider>
          ))} */}
          <GridContext>
          <Container  fluid>
          <Row>
          { 
            result.map((res) => (
              res.map((movie) => (
                <Col md="3" key={res.index}>
                  <Link 
                    to={"/details/" + movie.admin_video_id}
                    className="item_focus item5"
                  >
                    <img
                      className="item  item5 sliderthumb-text swiper-lazy view_all_col"
                      alt=""
                      src={movie.default_image}
                      loading="lazy"
                      aria-label={movie.title}
                      onLoad={this.focusOnFirstItem}
                    />
                  </Link>
              </Col>
              ))
          ))} 
          </Row>
          </Container>
          </GridContext>
        </div>
        <div className="height-100" />
      </div>
    );
  }
}

export default ViewAll;
