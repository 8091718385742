import React, { Component } from "react";
import { Link } from "react-router-dom";

import api from "../../Environment";

import HomePageBanner from "../NewUI/homePageBanner";
import ContentLoader from "../Static/contentLoader";
import configuration from "react-global-configuration";
import Slider from "../NewUI/MainSlider";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import { Keyboard, Lazy } from "swiper";

import Popup from "../User/Settings/Popup";

import { ShimmerSimpleGallery } from "react-shimmer-effects";

import { translate } from "react-multi-lang";
import VoiceService from "../Helper/VoiceService";

const inputData = {
  page_type: "HOME",
  id: 1,
  sub_profile_id: 1,
  token: "2y10Gv633zOPmq7IloFoF3HS5eRCLs3kqqeTjBfvNuU2XafLXApjW9Cy",
  skip: 0,
  take: 5,
};

class Home extends Component {
  state = {
    maindata: null,
    errorHandle: 0,
    loading: true,
    banner: null,
    loadingHomeSecondSection: true,
    loadingSeriesSection: true,
    loadingMoreSecondSection: false,
    hasMoreData: true,
    homeSecondData: null,
    seriesData: null,
    rowCount: 0,
    handleKeyDown: this.handleKeyDown.bind(this),
  };

  componentDidMount() {
    window.addEventListener("keydown", this.state.handleKeyDown);
    api
      .postMethod("home_first_section_vizio", inputData)
      .then((response) => {
        if (response.data.success === true) {
          // let maindata = response.data.data;
          let banner = response.data.banner;

          this.setState({
            loading: false,
            maindata: [],
            banner: banner,
          });

          if (banner && banner.data && banner.data.length > 0) {
            setTimeout(() => {
              VoiceService(banner.data[0].title + "; " + banner.data[0].description);
            }, 1500);
          }
        } else {
          let errorHandle = 1;
          this.setState({ errorHandle });
        }
      })
      .catch(function (error) {});

    this.loadSeriesData(inputData);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.state.handleKeyDown);
  }

  handleKeyDown(event) {
    console.log("Keydown Landing Page");
    if (
      !localStorage.getItem("accessToken") &&
      window.location.pathname === "/"
    ) {
      if (event.code === "Enter") {
        this.props.history.push("/login");
      } else if (event.code === "Backspace") {
        this.setState({ openPopup: true });
        // window.VIZIO.exitApplication()
      }
    } else if (window.location.pathname === "/login") {
      if (event.code === "Backspace") {
        this.props.history.goBack();
      }
    }
  }

  async loadSeriesData(inputData) {
    let contentArray;
    await api
      .postMethod("home_series_section_vizio", inputData)
      .then((response) => {
        if (response.data.success === true) {
          contentArray = response.data.data;
          this.setState({
            loadingSeriesSection: false,
            seriesData: response.data.data,
          });
        } else {
          let errorHandle = 1;
          this.setState({ errorHandle });
        }
      })
      .catch(function (error) {});
    this.loadSecondData(inputData, contentArray);
  }

  async loadSecondData(inputData, contentArray) {
    let data;
    await api
      .postMethod("home_second_section_new_vizio", inputData)
      .then((response) => {
        if (response.data.success === true) {
          data = response.data.data;
          if (this.state.rowCount === 0 && contentArray && contentArray.length > 0) {
            let ttsText = contentArray[0].title + "; ";
            if (contentArray[0].data.length > 0) {
              ttsText += contentArray[0].data[0].title;
            }

            VoiceService(ttsText);
          }
          this.setState({
            loadingHomeSecondSection: false,
            loadingMoreSecondSection: false,
            homeSecondData: contentArray.concat(data),
            hasMoreData: data.length >= inputData.take,
            homeData: response.data.Sub_Categories,
            rowCount: this.state.rowCount + data.length,
          });
        } else {
          let errorHandle = 1;
          this.setState({ errorHandle });
        }
      })
      .catch(function (error) {});
  }

  loadMoreSecondData() {
      inputData.skip += 5;
      this.setState({
        loadingMoreSecondSection: true,
      })
      this.loadSecondData(inputData, this.state.homeSecondData)
  }

  getAriaLabelFromItem(swiper, isPreviousCheck) {
    if (!(isPreviousCheck && swiper.realIndex == swiper.previousIndex) && swiper.realIndex >= 0 && swiper.wrapperEl.children.length > swiper.realIndex) {
     return swiper.wrapperEl.children[swiper.realIndex].firstChild.ariaLabel;
    }
  
    return "";
  }
  
  onSlideChange(swiperCore) {
    const {
      activeIndex,
      snapIndex,
      previousIndex,
      realIndex,
    } = swiperCore;
    console.log({ activeIndex, snapIndex, previousIndex, realIndex });
    if (activeIndex - this.state.maindata.length - this.state.seriesData.length > inputData.skip && this.state.hasMoreData) {
      this.loadMoreSecondData();
    }
  
    var ttsText = "";
    const swiperElement = swiperCore.wrapperEl.children[realIndex].children[0];
    ttsText = swiperElement.textContent + "; ";
    const swiperItem = swiperElement.children[1].swiper;
    ttsText += this.getAriaLabelFromItem(swiperItem, false);
  
    VoiceService(ttsText);
}

  renderVideoList = (maindata, index) => {
    return (
      <React.Fragment key={index}>
        <div className={"main-slidersec" + index}>
          <Link
            to={{
              pathname: "/view-all",
              state: {
                url_type: maindata.url_type,
                url_type_id: maindata.url_type_id,
                page_type: "HOME",
                title: maindata.title,
              },
            }}
          >
            <h3 className="">
              {maindata.title}
              <i className="fas fa-angle-right ml-2" />
            </h3>
          </Link>
          {maindata.data.length > 3 ? (
            <Swiper
              spaceBetween={0}
              slidesPerView={4}
              loop={true}
              lazy={true}
              keyboard={{
                enabled: (swiper) => (swiper.activeIndex ? false : true),
              }}
              modules={[Keyboard, Lazy]}
              preloadImages={true}
              onSlideChange={(swiper) => {
                if (swiper.realIndex != swiper.previousIndex && swiper.realIndex >= 0 && swiper.wrapperEl.children.length > swiper.realIndex) {
                  VoiceService(this.getAriaLabelFromItem(swiper, true));
                 }
              }}
              onSwiper={(swiper) => 
                swiper.activeIndex
                  ? (swiper.keyboard.enabled = false)
                  : (swiper.keyboard.enabled = true)
              }
            >
              {maindata.data.map((movie) => (
                <SwiperSlide key={movie.admin_video_id}>
                  <Link to="/#" className="item_focus item5"
                    aria-label={movie.title}>
                    <img
                      className="item  item5 sliderthumb-text swiper-lazy"
                      alt=""
                      src={movie.default_image}
                    />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <Swiper
              spaceBetween={0}
              slidesPerView={maindata.data.length}
              loop={true}
              lazy={true}
              keyboard={{
                enabled: (swiper) => (swiper.activeIndex ? false : true),
              }}
              modules={[Keyboard, Lazy]}
              preloadImages={true}
              onSlideChange={() => {}}
              onSwiper={(swiper) =>
                swiper.activeIndex
                  ? (swiper.keyboard.enabled = false)
                  : (swiper.keyboard.enabled = true)
              }
            >
              {maindata.data.map((movie) => (
                <SwiperSlide key={movie.admin_video_id}>
                  <Link
                    to="/subscribe-with-Stripe"
                    className="item_focus item5"
                  >
                    <img
                      className="item  item5 sliderthumb-text"
                      alt=""
                      src={movie.default_image}
                    />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </React.Fragment>
    );
  };

  elementInViewport(el) {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while (el.offsetParent) {
      el = el.offsetParent;
      top += el.offsetTop;
      left += el.offsetLeft;
    }

    return (
      top >= window.pageYOffset &&
      left >= window.pageXOffset &&
      top + height <= window.pageYOffset + window.innerHeight &&
      left + width <= window.pageXOffset + window.innerWidth
    );
  }

  render() {
    const { t } = this.props;

    const {
      loading,
      maindata,
      banner,
      loadingHomeSecondSection,
      loadingMoreSecondSection,
      homeSecondData,
      seriesData,
    } = this.state;

    return (
      <div className="main-sec-content landingPage">
        {loading ? <ContentLoader /> : <HomePageBanner banner={banner} isHideButton={true}/>}
        <div className="main p-40 swiper_section">
          {loading
            ? ""
            : maindata.map((mainDa, index) =>
                mainDa.data.length === 0
                  ? ""
                  : loading
                  ? t("")
                  : this.renderVideoList(mainDa, index)
              )}
          {/* {loadingSeriesSection
            ? ""
            : seriesData.map((mainDa, index) =>
                mainDa.data.length === 0 ? "" : loading ? "" : ""
              )} */}
              
          {loadingHomeSecondSection ? (
            !loading ? (
              <ShimmerSimpleGallery card imageHeight={200} rows={1} col={4} />
            ) : (
              <ContentLoader />
            )
          ) : (
            <Swiper
              direction={"vertical"}
              spaceBetween={0}
              slidesPerView={1}
              height={
                Math.max(
                  document.documentElement.clientHeight,
                  window.innerHeight || 0
                ) / 2.8
              }
              loop={false}
              lazy={true}
              keyboard={{
                enabled: true,
              }}
              modules={[Keyboard, Lazy]}
              preloadImages={true}
              onSlideChange={(swiperCore) => this.onSlideChange(swiperCore)}
              onSwiper={(swiper) => {}}
            >
              {homeSecondData.map((mainDa, index) =>
                mainDa.data.length === 0 ? (
                  ""
                ) : loading ? (
                  ""
                ) : (
                  <SwiperSlide className="v-slider" key={index}>
                    {this.renderVideoList(mainDa, index)}
                  </SwiperSlide>
                )
              )}
            </Swiper>
          )}
          {(loadingMoreSecondSection ? (
              <ShimmerSimpleGallery card imageHeight={200} rows={1} col={4} />) : "")}
          <div className="height-100" />
        </div>
      </div>
    );
  }
}

export default translate(Home);
