import React from "react";

import RegisterComponent from "../Auth/Register";
import { withRouter } from "react-router-dom";

const PaymentDetails = () => (
  <RegisterComponent stripe={null} elements={null} />
);

export default withRouter(PaymentDetails);
