import React, { Component } from "react";
import VoiceService from "../../Helper/VoiceService";

class Terms extends Component {
    state = {
        handleKeyDown: this.handleKeyDown.bind(this),
    };
    handleKeyDown(event) {
        switch (event.code) {
            case "Backspace":
                this.props.history.goBack();
                break;
            case "ArrowDown":
                this.focusOnNextElement(document.activeElement.nextSibling);
                break;
            case "ArrowUp":
                this.focusOnNextElement(document.activeElement.previousSibling);
                break;
            default:
                break;
        }
    }

    componentDidMount() {
        window.addEventListener("keydown", this.state.handleKeyDown);
        let settingsElements = document.getElementsByClassName("settings");
        if (settingsElements[0]) {
            this.focusOnNextElement(settingsElements[0].firstChild);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.state.handleKeyDown);
    }

    focusOnNextElement(element) {
        if (element) {
            element.tabIndex = 0;
            element.focus();
            VoiceService(element.innerText);
        }
    }

    render() {
        return (
            <div style={{ margin: 20 }} className="settings">
                <h1>terms</h1>
                <p><strong>Wrestling Legends Network TERMS AND CONDITIONS</strong></p>

                <p>Effective July 1st, 2021</p>

                <p>At Wrestling Legends Network, we strive to provide a great viewing experience for all who use our service. Please read the Terms of Use carefully, as it establishes legally binding terms and conditions that apply to your use of Wrestling Legends Network Services. The following terms describe and pertain to services offered under the “Wrestling Legends Network” brand provided by Stream Go Media, and available on all applicable third-party platforms as well as www.WrestlingLegendsNetwork.com</p>

                <p>These terms apply to anyone engaging with the Wrestling Legends Network brand regardless of platform used or consumer status with the Wrestling Legends Network brand.</p>

                <p>If you do not wish to agree with the Terms and Conditions, please do not engage, view, or subscribe to any of Wrestling Legends Network’s brands or services.</p>

                <p><strong>Changes to Terms</strong></p>

                <p>Wrestling Legends Network may change terms and conditions from time to time. All changes will be announced to the Terms and Conditions page located at <a href="http://www.HORRORMAX.COM/Terms">www.WrestlingLegendsNetwork.com/Terms</a> 30 days prior to implementation for current subscribers and effective immediately for subscribers interacting with the Wrestling Legends Network brand after change posting.</p>

                <p><strong>Age of Usage</strong></p>

                <p>Wrestling Legends Network along with all products and services provided under the Wrestling Legends Network brand are not intended for consumption by anyone under the age of 18 years old. Due to the nature of the content presented by the Wrestling Legends Network brand, the account(s) in relation to any misrepresented users found to be under the age of 18 will be immediately revoked from all Wrestling Legends Network brand services.</p>

                <p><strong>Termination of Service</strong></p>

                <p>You may cancel your subscription to Wrestling Legends Network at any time for any reason by visiting your account page located at <a href="http://www.HORRORMAX.com">www.WrestlingLegendsNetwork.com</a>. Subscriptions auto renew until time of cancelation by customer is received by Wrestling Legends Network. Wrestling Legends Network not provide refunds on partial subscription terms or unwatched content and your subscription will remain active until the end of your current billing cycle. Customers are responsible for any taxes or platform fees and/or charges associated with any service or product offered by the Wrestling Legends Network brand.</p>

                <p>We may terminate your right to access Wrestling Legends Network's&nbsp;services and products at any time without compensation or notice if you are found to be in violation of the terms and conditions set in this document. Subscription terms and pricing is at the sole discretion of Wrestling Legends Network. Current customers will receive notice of any price changes via their registered account email no later than 30 days prior to effective date.</p>

                <p><strong>Promotions and Trials</strong></p>

                <p>Wrestling Legends Network may at its sole discretion offer free trials, promotions terms, or credits to customers or potential customers. Wrestling Legends Network will be under any obligation to provide credits or promotions to any of its customer base existing or otherwise.</p>

                <p><strong>Access</strong></p>

                <p>Wrestling Legends Network's streaming services are offered through third-party streaming platforms and devices. You must have access to a compatible device to access Wrestling Legends Network. Internet connectivity is also required to access content offered by Wrestling Legends Network. Slower internet connections may from time to time interfere with HORRMAX being displayed at its optimal performance. Compatible devices and optimal internet connection is the sole responsibly of the user, not Wrestling Legends Network.</p>

                <p><strong>Customer Service</strong></p>

                <p>You may reach out to customer service anytime by emailing: <a href="mailto:Support@HORRORMAX.COM">Support@WrestlingLegendsNetwork.com</a>. Customer inquiries will be answered in the order they are received M-F 9am-6pm EST.</p>

                <p><strong>Privacy</strong></p>

                <p>Wrestling Legends Network requires a unique user name and password as well as payment information that is complete, not in violation of any law, or is misrepresentative to access content. Customer information will not be shared outside of Wrestling Legends Network’s parent company Stream Go Media, subsidiaries, assignees, payment processing partners, or legal inquiry that requires such by law. In the event that consumer information is breached and exposed to outside sources, Wrestling Legends Network will notify customers potentially effected immediately and take appropriate actions to resecure said information. Wrestling Legends Network and Stream Go Media&nbsp;shall not be held liable for damages resulting in a breach of information in either a single or class-action capacity occurring as a result of a digital attack on our technology foreign or domestic via hacking, virus, spyware, or any other electronic extraction means.</p>

                <p><strong>UPDATES</strong></p>

                <p>Wrestling Legends Network will on occasion issues updates to services and software to continue to be secure and compatible with third-party streaming devices. Users will be required to except these updates and on occasion install updated software to ensure a safe and optimal viewing experience.</p>

                <p><strong>VIDEO CONTENT</strong></p>

                <p>Wrestling Legends Network's mission to provide our customers with the greatest collection of streaming content available. Wrestling Legends Network does reserve the right to modify the catalog of offering at its sole discretion. Titles may be added or removed from the platform without notice. All content may not be available in all geographic locations due to rights restrictions.</p>

                <p><strong>Rights</strong></p>

                <p>Upon subscription activation, Wrestling Legends Network grant said customer noncommercial consumption rights until deactivation of subscription.</p>

                <p><strong>Copyright Claims</strong></p>

                <p>Wrestling Legends Network owns, represents, or licenses content for its products and services from multiple outlets. Any claims of infringement to copyright or intellectual property misuse may be directed to:</p>

                <p>Copyright Agent</p>

                <p>c/o Wrestling Legends Network</p>

                <p>1501 Broadway Times Square</p>

                <p>New York, NY 10036</p>

                <p>All notifications must contain the following:</p>

                <p>Physical or verified electronic signature of person(s) authorized to act on the behave of claims holder of allegedly infringed work(s)</p>

                <p>Identification and location of allegedly infringed work(s) so we may remove said works immediately while the claim is active.</p>

                <p>Current contact information including name, email, and phone number.</p>

                <p>Nor Wrestling Legends Network or Stream Go Media shall be liable for any infringement due to misrepresentation by third party content sources and shall provide third party contact information following the conclusion of claims verification procedures.</p>

                <p><strong>Limitations of Liability</strong></p>

                <p>All products and services offered by the Wrestling Legends Network brand is offered “as is” and no assurances or guarantees are given that the products and services provided by the Wrestling Legends Network band will be available for use at any given point in time, will be error free, uninterrupted, virus-free, or free from cyber-attack either foreign or domestic. Consumers of products and services of the Wrestling Legends Network brand do so at their own sole risk.</p>

                <p><strong>Force Majeure</strong></p>

                <p>In no&nbsp;event shall Wrestling Legends Network or Stream Go Media be responsible or liable for any failure or delay in the performance of its obligations hereunder arising out of or caused by, directly or indirectly, forces beyond its control, including, without limitation, strikes, work stoppages, accidents, acts of war or terrorism, civil or military disturbances, medical pandemic, nuclear or natural catastrophes or acts of God, and interruptions, loss or malfunctions of utilities, communications or computer (software and hardware) services; it being understood that Wrestling Legends Network and Stream Go Media&nbsp;shall use reasonable efforts resume performance as soon as practicable under the circumstances.</p>

                <p><strong>Resolution of Disputes</strong></p>

                <p>In the case of any dispute against Wrestling Legends Network, Stream Go Media, or any of it's products or services all parties agree that an Arbitrator not any federal, state, provincial or local court or agency shall have exclusive authority to rule on the resolution of any claims or disputes brought against Wrestling Legends Network, Stream Go Media, or any of its products or services. You expressly waive any right you may have to arbitrate any dispute as a class action case and expressly wave any rights to a jury trial. Arbitration shall be held in the city of New York, New York services provided by a third-party arbitration service not affiliated with Wrestling Legends Network or the claimant. If a mutually agreeable arbitrator cannot be agreed on by both parties within 14 days of initial claim, then selection of a third-party arbitration services shall be selected and retained by Wrestling Legends Network solely.</p>

                <p>No action or claim may be brought against Wrestling Legends Network, Stream Go Media, or any of its products and/or services any later than one year (365 days) after the cause of action has arisen unless provided by law.</p>

                <p><strong>Copyright © 2021 Stream Go Media/</strong>WrestlingLegendsNetwork</p>
            </div>
        )
    }

}

export default Terms;