import EventName from "../Services/EventName";

var currentTTSContent = "";
var isHandlerAdded = false;

const addEventHandler = () => {
  document.addEventListener(EventName.VIZIO_LIBRARY_DID_LOAD, (e) => {
    VoiceService(currentTTSContent);
  });
};

const VoiceService = (text) => {
    if (!window.VIZIO) {
      if (!isHandlerAdded) {
        addEventHandler();
        isHandlerAdded = true;
      }
      currentTTSContent = text;
    } else if (window.VIZIO && window.VIZIO.Chromevox) {
      window.VIZIO.Chromevox.cancel();
      window.VIZIO.Chromevox.play(text);
    }


    console.log(text);
};

export default VoiceService;
