import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import Helper from "../Helper/helper";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import { translate } from "react-multi-lang";

//check mobile Devices
import MediaQuery from "react-responsive";

import configuration from "react-global-configuration";

class HomePageBanner extends Helper {
  constructor(props) {
    super(props);

    // this.focusRef = React.createRef(null);
    // this.focusRef2 = React.createRef(null);
    // this.focusRef3 = React.createRef(null);
    this.inputRef = React.createRef();
    // this.inputRef.current is null here
  }
  state = {
    wishlistApiCall: false,
    wishlistResponse: null,
    inputData: {},
    redirect: false,
    redirectPPV: false,
    redirectPaymentOption: false,
    videoDetailsFirst: null,
    playButtonClicked: false,
    wishlistStatusCheck: 0,
    isMuted: true,
    isHideButton: this.props.isHideButton,
  };
  componentDidMount() {
    // this.focusRef.current.focus();
    this.setState({ playButtonClicked: false });
    let wishlistStatusCheck = 0;
    if (this.props.banner.wishlist_status === 1) {
      wishlistStatusCheck = 1;
    } else {
      wishlistStatusCheck = 0;
    }
    this.setState({ wishlistStatusCheck });

    // window.addEventListener("keydown", event => {

    //     if (event.code == "ArrowRight") {
    //     let itemFocused = document.activeElement;
    //     let nextItem = itemFocused.nextElementSibling;
    //     if (nextItem) {
    //         nextItem.tabIndex = 0;
    //         nextItem.focus();
    //     }

    //   } else if (event.code == "ArrowLeft") {
    //     let itemFocused = document.activeElement;
    //     let nextItem = itemFocused.previousElementSibling;
    //     if (nextItem) {
    //         nextItem.tabIndex = 0;
    //         nextItem.focus();
    //     }

    // }

    //      if (event.code == "ArrowRight") {
    //         if(document.activeElement === this.focusRef.current) {
    //             this.focusRef2.current.focus();
    //         }
    //         if(document.activeElement === this.focusRef2.current) {
    //             this.focusRef3.current.focus();
    //          }

    // } else if (event.code == "ArrowLeft") {
    //     if(document.activeElement === this.focusRef2.current) {
    //         this.focusRef.current.focus();
    //     }
    //     if(document.activeElement === this.focusRef3.current) {
    //         this.focusRef2.current.focus();
    //     }
    //   }
    // })
  }
  handleWishList = (event, admin_video_id) => {
    event.preventDefault();
    let inputData = {
      ...this.state.inputData,
      admin_video_id: admin_video_id,
    };

    api.postMethod("wishlists/operations", inputData).then((response) => {
      if (response.data.success === true) {
        ToastDemo(this.props.toastManager, response.data.message, "success");
        this.setState({
          wishlistResponse: response.data,
          wishlistApiCall: true,
        });
        if (response.data.wishlist_id != null) {
          this.setState({
            wishlistStatusCheck: 1,
          });
        } else {
          this.setState({
            wishlistStatusCheck: 0,
          });
        }
      } else {
        ToastDemo(
          this.props.toastManager,
          response.data.error_messages,
          "error"
        );
      }
    });
  };

  handlePlayVideo = async (event, admin_video_id) => {
    event.preventDefault();

    this.setState({ playButtonClicked: true });
    let inputData = {
      ...this.state.inputData,
      admin_video_id: admin_video_id,
    };

    await this.onlySingleVideoFirst(inputData);

    this.redirectStatus(this.state.videoDetailsFirst);
  };

  getTitle(title) {
    return title.replace(/ *\([^)]*\) */g, "");
  }

  handleMuteVideo = (event) => {
    event.preventDefault();

    this.inputRef.current.muted = !this.inputRef.current.muted;

    this.setState({ isMuted: this.inputRef.current.muted });
  };

  render() {
    const { banner } = this.props;

    if (this.state.playButtonClicked) {
      const returnToVideo = this.renderRedirectPage(
        this.state.videoDetailsFirst
      );

      if (returnToVideo != null) {
        return returnToVideo;
      }
    }
    const { isMuted, isHideButton } = this.state;
    return (
      <section className={"banner-slider slider swiper_banner"}>
        {banner.data.map((video) => (
          <div className="banner-sec" key={video.admin_video_id}>
            <div className="banner-home relative">
              {/* <MediaQuery minDeviceWidth={1224}> */}
              <video
                className="banner_Video"
                muted
                ref={this.inputRef}
                autoPlay
                loop
              >
                <source src={video.trailer_video} type="video/mp4" />
              </video>
              {/* </MediaQuery> */}

              {/* <img
                                            className="banner_right_img"
                                            src={video.default_image}
                                            srcSet={
                                                video.default_image +
                                                " 1x," +
                                                video.default_image +
                                                " 1.5x," +
                                                video.default_image +
                                                " 2x"
                                            }
                                            alt=""
                                        /> */}
              {/* <div className="banner_right_overlay" /> */}
            </div>

            <div className="banner-content banner-content-main">
              <div className="banner-text-centeralign">
                <img
                  src={configuration.get("configData.site_logo")}
                  className="logo-img logo_img_banner desktop-logo"
                  alt="WorldOneTv"
                />
                <div className="banner-text-left">
                  <h1 className="banner_video_title">
                    {this.getTitle(video.title)}
                  </h1>
                  <h4 className="banner_video_text">{video.description}</h4>
                  <Link
                    to={{
                      pathname: `/video/${video.admin_video_id}`,
                      state: {
                        videoDetailsFirst: video,
                      },
                    }}
                    className="btn btn-outline-secondary btn-right-space br-0 play_trailer_btn inactive-color"
                    style={{ minWidth: 200, backgroundColor: "#AA0000", display: (isHideButton ? "none" : "") }}>
                    Play Trailer
                  </Link><Link
                    to={"/details/" + video.admin_video_id}
                    className="btn btn-outline-secondary btn-right-space br-0 more_info_btn inactive-color"
                    style={{ minWidth: 200, backgroundColor: "#AA0000", display: (isHideButton ? "none" : "") }}>
                    More Info
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>
    );
  }
}

export default withToastManager(translate(HomePageBanner));
