export const apiConstants = {
  // Common variables

  LOGIN_BY: "manual",

  DEVICE_TYPE: "web",

  DEVICE_TOKEN: "123456",

  // Song download status

  DOWNLOAD_INITIATE_STAUTS: 1,

  DOWNLOAD_PROGRESSING_STAUTS: 2,

  DOWNLOAD_PAUSE_STAUTS: 3,

  DOWNLOAD_COMPLETE_STAUTS: 4,

  DOWNLOAD_CANCEL_STAUTS: 5,

  // Wishlist status

  WISHLIST_CLEAR_ALL: 1,

  WISHLIST_CLEAR_SINGLE_AUDIO: 0,

  // History status

  HISTORY_CLEAR_ALL: 1,

  HISTORY_CLEAR_SINGLE_AUDIO: 0,

  // PAGE TYPES

  PRIVACY_PAGE_TYPE: "privacy",

  TERMS_PAGE_TYPE: "terms",

  // Hisotry status

  HISTORY_INITIATE_STATUS: 1,

  HISTORY_COMPLETE_STATUS: 2,

  HISTORY_DELETE_STATUS: 3,

  USER_API_CALL: "/user",

  HOST_API_CALL: "/provider",

  SERIES: 21,

  MOVIES: 25,

  RECENTLY_ADDED: 33,

  CATEGORY: 54,

  KIDS: 12,

  STRIPE_KEYS: "",

  // Social Login Credentials

  GOOGLE_CLIENT_ID: "",

  FACEBOOK_APP_ID: "",

  JWPLAYER_KEY: "M2NCefPoiiKsaVB8nTttvMBxfb1J3Xl7PDXSaw==",

  settingsUrl: "http://streamgoviziodev.xyz/userApi/get_settings_json",

  homeSettingsUrl: "http://streamgoviziodev.xyz/userApi/get_home_settings_json",

  socketUrl: "http://streamgoviziodev.xyz:3003/",

  // settingsUrl: "http://localhost:8000/userApi/get_settings_json",

  // homeSettingsUrl: "http://localhost:8000/userApi/get_home_settings_json",

  // socketUrl: "http://localhost:3003/",
};
