import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import VerticalTicketRip from "@mui-treasury/components/rip/verticalTicket";
import { useVerticalRipStyles } from "@mui-treasury/styles/rip/vertical";
import CardMedia from "@material-ui/core/CardMedia";

const mainColor = "#003399";
const lightColor = "#ecf2ff";
const borderRadius = 12;

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  card: {
    opacity: 0.6,
    overflow: "visible",
    background: "none",
    display: "flex",
    minWidth: 100,
    minHeight: 150,
    filter: "drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.3))",
    "& $moveLeft, $moveRight": {
      transition: "0.3s",
    },
    "&:hover": {
      "& $moveLeft": {
        transform: "translateX(-8px)",
        opacity: 1,
      },
      "& $moveRight": {
        transform: "translateX(8px)",
        opacity: 1,
      },
    },
    "&:active": {
      "& $moveLeft": {
        transform: "translateX(-8px)",
        opacity: 1,
      },
      "& $moveRight": {
        transform: "translateX(8px)",
        opacity: 1,
      },
    },
    [breakpoints.up("sm")]: {
      minWidth: 400,
    },
  },

  cardActive: {
    opacity: 1,
    overflow: "visible",
    background: "none",
    display: "flex",
    minWidth: 343,
    minHeight: 150,
    filter: "drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.3))",
    "& $moveLeft, $moveRight": {
      transition: "0.3s",
    },

    "& $moveLeft": {
      transform: "translateX(-8px)",
    },
    "& $moveRight": {
      transform: "translateX(8px)",
    },

    [breakpoints.up("sm")]: {
      minWidth: 400,
    },
  },

  left: {
    borderTopLeftRadius: borderRadius,
    borderBottomLeftRadius: borderRadius,
    flexBasis: "33.33%",
    display: "flex",
    backgroundColor: "#fff",
    alignItems: "center",
    textAlign: "center",
    padding: 12,
  },
  media: {
    margin: "auto",
    width: 120,
    height: 80,
    borderRadius: "50%",
  },
  right: {
    borderTopRightRadius: borderRadius,
    borderBottomRightRadius: borderRadius,
    flex: 1,
    padding: 12,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: lightColor,
  },
  label: {
    padding: "0 8px",
  },
  heading: {
    fontSize: 24,
    fontWeight: "bold",
    margin: 0,
    marginBottom: 4,
  },
  subheader: {
    fontSize: 12,
    margin: 0,
    color: palette.text.secondary,
  },
  moveLeft: {},
  moveRight: {},
  cardOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "rgba(128,128,128,0.75)",
    height: "100%",
    width: "100%",
  },
}));

export const PlanCard = ({ plan, chosenPlan, active }) => {
  // console.log(plan);
  // export class PlanCard extends React.Component {}
  const styles = useStyles();
  const ripStyles = useVerticalRipStyles({
    size: 24,
    rightColor: lightColor,
    tearColor: mainColor,
  });

  const clickHandler = (event) => {
    event.preventDefault();
    chosenPlan(plan);
  };

  const amount = plan.amount;

  return (
    <div className="cardBody" onClick={clickHandler}>
      <Card
        did=""
        className={active === "true" ? "cardActive" : "cards"}
        elevation={0}
      >
        <div className={cx("card_left", "moveLeft")}>
          <div className={"card_label"}>
            <h2 className={"card_heading"}>
              {plan.amount > 10 ? "ANNUAL" : "MONTHLY"}
            </h2>
            <p className={"card_subheader"}>Membership</p>
          </div>
        </div>

        {/* <VerticalTicketRip
                    classes={{
                        ...ripStyles,
                        left: cx('rip_left', 'moveLeft'),
                        right: cx('rip_right', 'moveRight'),
                    }} 
                /> */}
        <div className={cx("card_right", "moveRight")}>
          <div className={"card_label"}>
            {/* <div className="row">
                            <h2 className={styles.subheader}>Box : 12 </h2>
                            <h2 className={styles.subheader}>Row : A </h2>
                            <h2 className={styles.subheader}>Seat: 5</h2>
                        </div>
                         */}

            <h2 className={"card_heading"}>${plan.amount}</h2>
            {/* <p className={styles.subheader}> WEST </p> */}
          </div>
          {plan.amount > 10 ? (
            <CardMedia
              className={"card_media"}
              image={"assets/img/mostpop.png"}
            />
          ) : null}
        </div>
      </Card>
    </div>
  );
};

export default PlanCard;
