import React from "react";
import "../../NewUI/MainSlider/Content.scss";
import Helper from "../../Helper/helper";
import VideoOverView from "../../User/Video/videoOverView";
import VideoTrailer from "../../User/Video/videoTrailer";
import VideoMoreLikeThis from "../../User/Video/videoMoreLikeThis";
import VideoDetails from "../../User/Video/videoDetails";
import ContentLoader from "../../Static/contentLoader";
import classNames from "classnames";

const $ = window.$;
// const DATE_OPTIONS = {
//     year: "numeric",
//     month: "short"
// };

class VideoDetailsPage extends Helper {
  state = {
    videoDetailsFirst: null,
    loadingFirst: true,
    videoDetailsSecond: null,
    loadingSecond: true,
    suggestion: null,
    loadingSuggestion: true,
    nav: "overview",
    inputData: {
      admin_video_id: this.getMovieId(),
      skip: 0,
    },
    backImageUrl: null,
    index: 0,
  };

  getMovieId() {
    let str = window.location.href;
    str = str.split("/");
    const vidID = str[str.length - 1];

    return vidID;
  }

  showSliderContent() {
    $(".slider-content").css("display", "block");
  }

  closeSliderContent() {
    $(".slider-content").css("display", "none");
  }

  setFocusedItem = (nextItem) => {
    nextItem[0].tabIndex = 0;
    nextItem[0].focus();
  };

  componentDidMount() {
    this.singleVideoFirst(this.state.inputData);
    // this.interval = setInterval(() => {
    //   this.nextSlide(); //this function change the index state.
    // }, 5000);

    // window.addEventListener("keydown", (event) => {
    //   if (
    //     localStorage.getItem("accessToken") &&
    //     window.location.href.indexOf("details") > -1
    //   ) {
    //     if (event.code === "ArrowDown") {
    //       const playBtn = document.getElementsByClassName("play_btn");
    //       const trailerBtn = document.getElementsByClassName("trailer_btn");
    //       const seriestBtn = document.getElementsByClassName("series_btn");
    //       const mylistBtn = document.getElementsByClassName("mylist_btn");

    //       if (playBtn[0] === document.activeElement) {
    //         if (trailerBtn.length > 0) {
    //           this.setFocusedItem(trailerBtn);
    //           return;
    //         } else if (seriestBtn.length > 0) {
    //           this.setFocusedItem(seriestBtn);
    //           return;
    //         } else {
    //           this.setFocusedItem(mylistBtn);
    //           return;
    //         }
    //       } else if (trailerBtn[0] === document.activeElement) {
    //         if (seriestBtn.length > 0) {
    //           this.setFocusedItem(seriestBtn);
    //           return;
    //         } else {
    //           this.setFocusedItem(mylistBtn);
    //           return;
    //         }
    //       } else if (seriestBtn[0] === document.activeElement) {
    //         this.setFocusedItem(mylistBtn);
    //         return;
    //       }
    //     }

    //     if (event.code === "ArrowUp") {
    //       const playBtn = document.getElementsByClassName("play_btn");
    //       const trailerBtn = document.getElementsByClassName("trailer_btn");
    //       const seriestBtn = document.getElementsByClassName("series_btn");
    //       const mylistBtn = document.getElementsByClassName("mylist_btn");

    //       if (mylistBtn[0] === document.activeElement) {
    //         if (seriestBtn.length > 0) {
    //           this.setFocusedItem(seriestBtn);
    //           return;
    //         } else if (trailerBtn.length > 0) {
    //           this.setFocusedItem(trailerBtn);
    //           return;
    //         } else {
    //           this.setFocusedItem(playBtn);
    //           return;
    //         }
    //       } else if (seriestBtn[0] === document.activeElement) {
    //         if (trailerBtn.length > 0) {
    //           this.setFocusedItem(trailerBtn);
    //           return;
    //         } else {
    //           this.setFocusedItem(playBtn);
    //           return;
    //         }
    //       } else if (trailerBtn[0] === document.activeElement) {
    //         this.setFocusedItem(playBtn);
    //         return;
    //       }
    //     }

    //     if (event.code === "Backspace") {
    //       window.location = "/";
    //     }
    //   }
    // });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  nextSlide() {
    let ind = this.state.index;
    switch (ind) {
      case 0:
        this.setState({ index: 1 });
        break;
      case 1:
        this.setState({ index: 2 });
        break;
      default:
        this.setState({ index: 0 });
        break;
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      nav: "overview",
      loadingFirst: true,
      loadingSecond: true,
    });

    // this.forceUpdate();
    let inputData = {
      admin_video_id: this.getMovieId(),
    };

    this.singleVideoFirst(inputData);
  }

  navToggle = (link, event) => {
    event.preventDefault();
    this.setState({
      nav: link,
    });
    if (link == "related") {
      if (
        localStorage.getItem("current_video_id") ===
        this.state.inputData.admin_video_id
      ) {
        // Do nothing.
      } else {
        localStorage.setItem(
          "current_video_id",
          this.state.inputData.admin_video_id
        );
        this.suggestion(this.state.inputData);
      }
    }
  };

  render() {
    const movie = { ...this.props.movie };

    const styles = {
      containerStyle: {
        backgroundImage: `url(${
          this.state.backImageUrl
            ? this.state.backImageUrl[this.state.index]
            : movie.default_image
        })`,
        // opacity: this.state.loading ? 0 : 1,
        // transition: "opacity, 2s ease-in-out",
        transition: "background 1s linear",
      },
      dark: {
        background: "#00000047",
        width: "100%",
        height: "100%",
        zIndex: 2,
        position: "absolute",
      },
    };

    const {
      loadingFirst,
      videoDetailsFirst,
      videoDetailsSecond,
      loadingSecond,
      loadingSuggestion,
      suggestion,
    } = this.state;

    const { dark } = styles;
    const { containerStyle } = styles;
    return (
      <div className="content">
        <div style={dark}></div>{" "}
        <div className="content__background">
          <div className="content__background__shadow" />
          <div
            className="content__background__image"
            style={containerStyle}
            // style={{
            //     backgroundImage: `url(${movie.default_image})`
            // }}
          />
        </div>
        <div className="content__area">
          <div className="content__area__container">
            {/*
              <div className="content__title">{movie.title}</div>
              <div className="content__description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
                et euismod ligula. Morbi mattis pretium eros, ut mollis leo tempus
                eget. Sed in dui ac ipsum feugiat ultricies. Phasellus vestibulum enim
                quis quam congue, non fringilla orci placerat. Praesent sollicitudin
              </div> 
            */}
          </div>
          <div className="slider-content-tabcontent">
            <div className="tab-content">
              {loadingSecond ? (
                <ContentLoader />
              ) : (
                <div
                  className={classNames("tab-pane", {
                    active: this.state.nav == "overview",
                    fade: this.state.nav != "overview",
                  })}
                >
                  <VideoOverView
                    videoDetailsFirst={videoDetailsFirst}
                    trailer={videoDetailsSecond.trailer_section}
                    genreVideos={videoDetailsSecond.genre_videos}
                    genres={videoDetailsSecond.genres}
                    isSeries={videoDetailsSecond.is_series}
                  />
                </div>
              )}

              {loadingSecond ? (
                ""
              ) : (
                <div
                  className={classNames("tab-pane", {
                    active: this.state.nav == "episodes",
                    fade: this.state.nav != "episodes",
                  })}
                >
                  {/* <VideoEpisode
                    genreVideos={videoDetailsSecond.genre_videos}
                    genres={videoDetailsSecond.genres}
                  /> */}
                </div>
              )}

              {loadingSecond ? (
                ""
              ) : (
                <div
                  className={classNames("tab-pane", {
                    active: this.state.nav == "trailers",
                    fade: this.state.nav != "trailers",
                  })}
                >
                  <VideoTrailer trailer={videoDetailsSecond.trailer_section} />
                </div>
              )}

              <div
                className={classNames("tab-pane", {
                  active: this.state.nav == "related",
                  fade: this.state.nav != "related",
                })}
              >
                {loadingSuggestion ? (
                  ""
                ) : (
                  <VideoMoreLikeThis suggestion={suggestion} />
                )}
              </div>

              {loadingFirst ? (
                ""
              ) : (
                <div
                  className={classNames("tab-pane", {
                    active: this.state.nav == "details",
                    fade: this.state.nav != "details",
                  })}
                >
                  <VideoDetails videoDetailsFirst={videoDetailsFirst} />
                </div>
              )}
            </div>
          </div>
          <div className="slider-content-tabsec">
            <ul className="nav nav-pills" role="tablist">
              <li className="nav-item">
                <a
                  className={classNames("nav-link", {
                    active: this.state.nav == "overview",
                  })}
                  onClick={(event) => this.navToggle("overview", event)}
                  href="#"
                >
                  overview
                </a>
              </li>
              {loadingFirst ? (
                ""
              ) : videoDetailsFirst.is_series ? (
                <li className="nav-item">
                  <a
                    className={classNames("nav-link", {
                      active: this.state.nav == "episodes",
                    })}
                    onClick={(event) => this.navToggle("episodes", event)}
                    href="#"
                  >
                    episodes
                  </a>
                </li>
              ) : (
                ""
              )}

              {loadingSecond
                ? ""
                : videoDetailsSecond.trailer_section.length && (
                    <li className="nav-item">
                      <a
                        className={classNames("nav-link", {
                          active: this.state.nav == "trailers",
                        })}
                        onClick={(event) => this.navToggle("trailers", event)}
                        href="#"
                      >
                        Trailer
                      </a>
                    </li>
                  )}

              <li className="nav-item">
                <a
                  className={classNames("nav-link", {
                    active: this.state.nav == "related",
                  })}
                  onClick={(event) => this.navToggle("related", event)}
                  href="#"
                >
                  more like this
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoDetailsPage;
