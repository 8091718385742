import React from "react";
import api from "../../Environment";
import Helper from "../Helper/helper";
import configuration from "react-global-configuration";
import { translate } from "react-multi-lang";
import VoiceService from "../Helper/VoiceService";

var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class LoginCommponent extends Helper {
  state = {
    data: {
      acrivationCode: "",
      timezone: const_time_zone,
    },
    loadingContent: null,
    buttonDisable: false,
    vCode: Math.random().toString(36).substr(2, 6),
    isActive: false,
    handleKeyDown: this.handleKeyDown.bind(this),
  };

  componentDidMount() {
    console.log(this.props.history);
    console.log(this.props.history.goBack.length);
    console.log(this.props.history.goBack);
    window.addEventListener("keydown", this.state.handleKeyDown);

    const ttsText = document.querySelector(".vizio_div").innerText;
    VoiceService(ttsText);

    setInterval(this.checkActivation, 10000);
  }
  
  componentWillUnmount() {
    window.removeEventListener("keydown", this.state.handleKeyDown);
  }

  handleKeyDown(event) {
    console.log("Keydown Login Page");
    if (window.location.href.indexOf("login") > -1) {
      if (event.code === "Backspace") {
        this.props.history.goBack();
      }
    }
  }

  checkActivation = () => {
    // console.log(this.state.isActive);
    if (this.state.isActive) return;

    const data = {
      id: 1,
      sub_profile_id: 1,
      token: "2y10Gv633zOPmq7IloFoF3HS5eRCLs3kqqeTjBfvNuU2XafLXApjW9Cy",
      vCode: this.state.vCode,
    };
    api
      .postMethod("v4/is_vizio_activated", data)
      .then((response) => {
        // console.log(response.data.data);
        if (response.data.success) {
          this.setState({ isActive: true });
          localStorage.setItem("userId", response.data.data.user_id);
          localStorage.setItem("accessToken", response.data.data.token);
          localStorage.setItem("userType", response.data.data.user_type);
          localStorage.setItem("push_status", response.data.data.push_status);
          localStorage.setItem("username", response.data.data.name);
          localStorage.setItem(
            "active_profile_id",
            response.data.data.sub_profile_id
          );

          this.props.history.push("/view-profiles");
        } else {
          this.setState({
            isActive: false,
          });
        }
      })
      .catch((error) => {});
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loadingContent: this.props.t("button_loading"),
      buttonDisable: true,
    });
    api
      .postMethod("v4/login", this.state.data)
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem("userId", response.data.data.user_id);
          localStorage.setItem("accessToken", response.data.data.token);
          localStorage.setItem("userType", response.data.data.user_type);
          localStorage.setItem("push_status", response.data.data.push_status);
          localStorage.setItem("username", response.data.data.name);
          localStorage.setItem(
            "active_profile_id",
            response.data.data.sub_profile_id
          );

          this.props.history.push("/view-profiles");
          this.setState({
            loadingContent: null,
            buttonDisable: false,
          });
        } else {
          this.setState({
            loadingContent: null,
            buttonDisable: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loadingContent: null, buttonDisable: false });
      });
  };

  render() {
    const { t } = this.props;
    var bgImg = {
      backgroundImage: `url(${configuration.get(
        "configData.common_bg_image"
      )})`,
    };
    const { data } = this.state;
    // const { vCode } = Math.random().toString(36).substr(2, 6);

    return (
      <div className="Vizio_Code">
        <div className="common-bg-img" style={bgImg}></div>
        <div className="vizio_div">
          <h3 className="register-box-head-vizio">
            To start watching do the following.
          </h3>
          <h4 className="h5-vizio">
            Visit{" "}
            <span className="vizio_span">
              {" "}
              wrestlinglegendsnetwork.tv/vizio
            </span>{" "}
            on your computer or mobile device and enter the following code{" "}
          </h4>
          <br></br>
          <h3 className="activation-code">{this.state.vCode}</h3>
          <br></br>
          <h4 className="h5-vizio">When complete, the screen will refresh.</h4>
        </div>
        {/* <div className="row">
                        <div className="col-sm-9 col-md-7 col-lg-5 col-xl-4 auto-margin">
                            <div className="register-box activation-box">
                                <h3 className="register-box-head">
                                    How To Activate Your Account
                                </h3><br></br>
                                <h5>Go to </h5><br></br><h5 className="activation-red">wrestlinglegendsnetwork.tv/vizio </h5>
                                <br></br><h5>and use the following code</h5>
                                <br></br><h3 className="activation-code">{Math.random().toString(36).substr(2, 6)}</h3><br></br> 
                                {/* Math.floor(100000 + Math.random() * 900000) */}
        {/* <button
                                        tabindex={0}
                                        ref={(ip) => this.btnSub = ip}
                                        className="btn btn-danger auth-btn"
                                        disabled={this.state.buttonDisable}
                                    >
                                        {this.state.loadingContent != null
                                            ? this.state.loadingContent
                                            : "Done"}
                                    </button>

                                
                                 
                            </div>
                            
                        </div> */}
        {/* </div>  */}

        {/* </div> */}
      </div>
    );
  }
}

export default translate(LoginCommponent);
