import React, { useRef } from "react";
import { Link } from "react-router-dom";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import Helper from "../Helper/helper";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import { Keyboard, Lazy } from "swiper";

import Keyboardd from "react-simple-keyboard";
import keyNavigation from "simple-keyboard-key-navigation";
import "react-simple-keyboard/build/css/index.css";
import VoiceService from "../Helper/VoiceService";

class SearchComponent extends Helper {
  state = {
    data: {
      value: "",
    },
    redirect: false,
    redirectPPV: false,
    redirectPaymentOption: false,
    videoDetailsFirst: null,
    playButtonClicked: false,
    loading: true,
    genreVideos: null,
    inputData: {
      admin_video_id: 7,
      skip: 0,
    },
    videoDetailsSecond: null,
    loadingSecond: true,

    episodes: null,
    btns: null,

    activeSlide: 0,
    isSwiper: true,
    swiper: null,
    handleKeyDown: this.handleKeyDown.bind(this)
  };

  sliderRef = (slider) => {
    this.slider = slider;
  };

  getMovieId() {
    let str = window.location.href;
    str = str.split("/");
    const vidID = str[str.length - 1];

    return vidID;
  }

  // TO DO THIS
  // Find a way to check
  // wherte i am on the keyboard so when its focuse on onfe of the last letter
  // like f i r .. etc sp i can focuse on the swiper
  componentDidMount() {
    // this.singleVideoSecond(this.state.inputData);
    const string = "abcdefghijklmnopqrstuvwxyz";
    this.handleSearchChange(
      string[Math.floor(Math.random() * string.length)],
      true
    );

    this.setState({ playButtonClicked: false });

    window.addEventListener("keydown", this.state.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.state.handleKeyDown);
  }

  handleKeyDown(event) {

    console.log("Keydown Search Page");
    if (
      localStorage.getItem("accessToken") &&
      window.location.href.indexOf("search") > -1
    ) {
      // console.log(this.keyboardd);
      // console.log(this.keyboardd.getInput());
      if (event.code === "Enter") {
        if (this.state.isSwiper) {
          const swiper = document.getElementsByClassName(
            "swiper-slide-active"
          );
          const movie = swiper[0].getElementsByTagName("a");
          //  console.log(moviePath);
          movie[0].click();
        } else {
          event.preventDefault();
          this.keyboardd.modules.keyNavigation.press();
        }
      }

      if (event.code === "Backspace") {
        window.location = "/#/";
      }

      if (event.code === "ArrowLeft") {
        if (this.state.isSwiper === true) {
          this.setState({ isSwiper: false });

          let swiper = this.state.swiper;
          // console.log(swiper);
          if (swiper === null) return;
          if (swiper.destroyed) return;
          if (typeof swiper === "undefined") return;
          if (swiper.keyboard) {
            swiper.keyboard.disable();
          }

          const swiperElement = document.getElementsByClassName(
            "swiper-slide-active"
          );
          if (swiperElement.length > 0) {
            const movie = swiperElement[0].getElementsByTagName("a");
            const poster = movie[0].getElementsByTagName("img");
            poster[0].classList.add("btn_noFocus");
          }
        } else {
          this.keyboardd.modules.keyNavigation.left();
          this.onKeyboardFocusChanged();
        }
      }

      if (!this.state.isSwiper) {
        if (event.code === "ArrowUp") {
          this.keyboardd.modules.keyNavigation.up();
          this.onKeyboardFocusChanged();
        }
        else if (event.code === "ArrowDown") {
          this.keyboardd.modules.keyNavigation.down();
          this.onKeyboardFocusChanged();
        }
      }

      if (event.code === "ArrowRight") {
        if (this.state.isSwiper === false) {
          const swiperElement = document.getElementsByClassName(
            "swiper-slide-active"
          );
          const keysArray = ["f", "l", "r", "x", "4", "0", "⟵"];
          const btn = document.getElementsByClassName("hg-keyMarker");
          const value = btn[0].getElementsByTagName("span")[0].innerHTML;

          if (keysArray.includes(value)) {
            const swiperElement = document.getElementsByClassName(
              "swiper-slide-active"
            );
            if (swiperElement.length > 0) {
              this.setState({ isSwiper: true });
              let swiper = this.state.swiper;
              if (swiper === null) return;
              if (swiper.destroyed) return;
              if (typeof swiper === "undefined") return;
              if (swiper.keyboard) {
                swiper.keyboard.enable();
              }
              const movie = swiperElement[0].getElementsByTagName("a");
              const poster = movie[0].getElementsByTagName("img");

              poster[0].classList.remove("btn_noFocus");
              this.onHandleSwiperChange(swiper);
            }
          } else {
            this.keyboardd.modules.keyNavigation.right();
            this.onKeyboardFocusChanged();
          }
        }
      }
    }
  }

  singleVideoSecond(inputData) {
    api
      .postMethod("videos/view/second", inputData)
      .then((response) => {
        if (response.data.success === true) {
          let videoDetailsSecond = response.data.data;

          this.setState({
            loadingSecond: false,
            episodes: videoDetailsSecond.genre_videos,
            btns: videoDetailsSecond.genres.reverse(),
          });
        } else {
        }
      })
      .catch(function (error) { });
  }
  handleSearchChange = (input, first) => {
    api
      .postMethod("search_videos", { key: input })
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            loading: false,
            loadingSecond: false,
            genreVideos: response.data.data,
          });
          if (!first) {
            const swiperElement = document.getElementsByClassName(
              "swiper-slide-active"
            );
            if (swiperElement.length > 0) {
              const movie = swiperElement[0].getElementsByTagName("a");
              const poster = movie[0].getElementsByTagName("img");
              poster[0].classList.add("btn_noFocus");
            }
          }
        }
      })
      .catch(function (error) { });
  };

  handleGenre = (genre_id) => {
    let inputData = {
      ...this.state.inputData,
      genre_id: genre_id,
      skip: 0,
    };
    // const data = { ...this.state.data };
    // data[name] = genre_id;
    // this.setState({ data });

    api
      .postMethod("genre_videos", inputData)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            loading: false,
            genreVideos: response.data.data,
          });
        } else {
          // Do nothing
        }
      })
      .catch(function (error) { });
  };

  handlePlayVideo = async (event, admin_video_id) => {
    event.preventDefault();

    let inputData = {
      ...this.state.inputData,
      admin_video_id: admin_video_id,
    };

    await this.onlySingleVideoFirst(inputData);

    if (this.state.videoDetailsFirst.success === false) {
      ToastDemo(
        this.props.toastManager,
        this.state.videoDetailsFirst.error_messages,
        "error"
      );
    } else {
      this.redirectStatus(this.state.videoDetailsFirst);
      this.setState({ playButtonClicked: true });
    }
  };

  // keyboard
  onChange = (input) => {
    document.querySelector(".search_field").value = input;
    this.handleSearchChange(input, false);
  };

  onKeyboardFocusChanged = () => {
    const markerPosition = this.keyboardd.modules.keyNavigation.markerPosition;
    const currentKeyButton = this.keyboardd.modules.keyNavigation.getButtonAt(markerPosition.row, markerPosition.button);
    VoiceService(currentKeyButton.textContent);
  }

  onHandleSwiperChange = (swiper) => {
    const {
      activeIndex,
      snapIndex,
      previousIndex,
      realIndex,
    } = swiper;

    var ttsText = "";
    const swiperElement = swiper.wrapperEl.children[realIndex].children[0];
    ttsText = swiperElement.innerText;
    // const swiperItem = swiperElement.children[1].swiper;
    // ttsText += this.getAriaLabelFromItem(swiperItem, false);

    VoiceService(ttsText);

  }

  onKeyPress = (button) => {
    console.log("Button pressed", button);
    VoiceService(button);

    if (button === "{shift}" || button === "{lock}") this.handleShift();
    if (button === "{hide}") {
      this.setState({ showKeyboard: false });
      this.btnSub.focus();
    }
  };

  render() {
    if (this.state.playButtonClicked) {
      const returnToVideo = this.renderRedirectPage(
        this.state.videoDetailsFirst
      );

      if (returnToVideo != null) {
        return returnToVideo;
      }
    }
    let genreVideos;
    // const { genres } = this.state.videoDetailsSecond.genres;
    const { loading, loadingSecond, episodeSlider } = this.state;
    if (loading) {
      if (!loadingSecond) {
        genreVideos = this.state.episodes;
      }
    } else {
      genreVideos = this.state.genreVideos;
    }

    return (
      <div>
        {loadingSecond ? (
          <div></div>
        ) : (
          <div className="row">
            {/* left Part */}
            <div className="col-4 eps_btn">
              <div className="row">
                <input
                  type="text"
                  name="search"
                  placeholder="title..."
                  className={
                    "form-control search-form  search-focus search_field"
                  }
                  onChange={this.handleSearchChange}
                />
              </div>

              <div className="row">
                <div className="col-12 mt-5">
                  <Keyboardd
                    keyboardRef={(r) => (this.keyboardd = r)}
                    layoutName={"default"}
                    modules={[keyNavigation]}
                    enableKeyNavigation={true}
                    onChange={this.onChange}
                    onKeyPress={this.onKeyPress}
                    layout={{
                      default: [
                        "{space} {bksp}",
                        "a b c d e f",
                        "g h i j k l",
                        "m n o p q r",
                        "s t u v w x",
                        "y z 1 2 3 4",
                        "5 6 7 8 9 0",
                      ],
                    }}
                    display={{
                      "{bksp}": "Backspace",
                      "{space}": "Space",
                    }}
                    buttonTheme={[
                      {
                        class: "actionBtns",
                        buttons: "{bksp} {space}",
                      },
                      {
                        class: "spacebar",
                        buttons: "{space}",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>

            {/* right Part */}
            <div className="col-8 p-5">
              <Swiper
                className={"ep_swiper"}
                direction={"vertical"}
                spaceBetween={10}
                slidesPerView={1}
                lazy={true}
                height={200}
                width={30}
                keyboard={{ enabled: true }}
                modules={[Keyboard, Lazy]}
                onSlideChange={(swiperCore) => this.onHandleSwiperChange(swiperCore)}
                onSwiper={(swiper) => {
                  this.setState({ swiper: swiper });
                }}
              >
                {genreVideos.map((video) => (
                  <SwiperSlide className="ep-slider">
                    <div key={video.admin_video_id}>
                      <div className="row">
                        <div className="col-4">
                          <div className="relative">
                            <Link
                              to="#"
                              onClick={(event) =>
                                this.handlePlayVideo(
                                  event,
                                  video.admin_video_id
                                )
                              }
                            >
                              <img
                                className="trailers-img placeholder"
                                alt="episode-img"
                                src={video.default_image}
                                data-src="assets/img/thumb1.jpg"
                                srcSet={
                                  video.default_image +
                                  " 1x," +
                                  video.default_image +
                                  " 1.5x," +
                                  video.default_image +
                                  " 2x"
                                }
                                data-srcset="assets/img/thumb1.jpg 1x,
                            assets/img/thumb1.jpg 1.5x,
                            assets/img/thumb1.jpg 2x"
                              />
                              <div className="trailers-img-overlay"></div>
                            </Link>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="episode-content">
                            <div className="row">
                              <div className="col-xl-8 col-lg-8">
                                <h4 className="episode-content-head">
                                  {video.title}
                                </h4>
                              </div>
                              <div className="col-xl-4 col-lg-4">
                                <h4 className="episode-content-head text-right">
                                  {video.duration}
                                </h4>
                              </div>
                            </div>
                            <h4 className="episode-content-desc">
                              {video.description}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withToastManager(SearchComponent);
