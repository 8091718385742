import React from "react";
import { Link } from "react-router-dom";
import Helper from "../Helper/helper";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import configuration from "react-global-configuration";

import { translate } from "react-multi-lang";
import PlanCard from "../NewUI/PlanCard";
import { withRouter } from "react-router-dom";

var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class RegisterComponent extends Helper {
  // const RegisterComponent = () => {

  constructor(props) {
    super(props);

    this.SetChosenPlan = this.SetChosenPlan.bind(this);
  }

  SetChosenPlan(plan) {
    this.handlePromoCodeRemove();
    this.setState({ chosenPlan: plan });
    this.setState({ data: { coupon_code: "" } });

    if (plan.amount > 10) {
      this.setState({ isAnnual: true });
    } else {
      this.setState({ isAnnual: false });
    }
  }

  state = {
    data: {
      email: "",
      password: "",
      name: "",
      timezone: const_time_zone,
      referral_code: "",
      nameOnCard: localStorage.getItem("username")
        ? localStorage.getItem("username")
        : "",
      postalCode: "",
      coupon_code: "",
    },

    loadingContent: null,
    buttonDisable: false,
    check_referral_response: "",

    subscriptions: [],
    loading: true,
    accessToken: false,
    total: 0,

    promoCode: null,
    loadingPromoCode: true,
    paymentMode: "card",
    loadingContentCard: null,
    buttonDisableCard: false,
    pay_amount: 0,
    chosenPlan: null,
    isAnnual: true,

    user_id: null,
    token: null,
  };

  componentDidMount() {
    const data = {
      sub_profile_id: "",
      token: localStorage.getItem("accessToken")
        ? localStorage.getItem("accessToken")
        : "2y10Gv633zOPmq7IloFoF3HS5eRCLs3kqqeTjBfvNuU2XafLXApjW9Cy",
      id: localStorage.getItem("userId") ? localStorage.getItem("userId") : 1,
    };

    api.postMethod("subscription_plans", data).then((response) => {
      if (response.data.success === true) {
        let subscriptions = response.data.data;
        this.setState({
          loading: false,
          subscriptions: subscriptions,
          chosenPlan: subscriptions[1],
        });

        if (localStorage.getItem("accessToken")) {
          this.setState({ accessToken: true });
        } else {
          this.setState({ accessToken: false });
        }
      }
    });
  }

  testbtn = (event) => {
    event.preventDefault();
    this.setState({ accessToken: true });
  };

  signupForm() {
    var bgImg = {
      backgroundImage: `url(${configuration.get(
        "configData.common_bg_image"
      )})`,
    };
    const { data } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div className="common-bg-img" style={bgImg}>
          <div className="auth-page-header">
            <Link to={"/"}>
              <img
                src={configuration.get("configData.site_logo")}
                className="site-logo"
                alt="logo_img"
              />
            </Link>
          </div>

          <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-5 col-xl-4 auto-margin">
              <div className="register-box">
                <p className="steps">Step 1 of 2</p>
                <h3 className="register-box-head free_acount">
                  Create your Account
                </h3>
                <form className="auth-form" onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="name">{t("full_name")}</label>
                    <input
                      onChange={this.handleChange}
                      className="form-control"
                      id="name"
                      name="name"
                      value={data.name}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">{t("email_address")}</label>
                    <input
                      type="email"
                      onChange={this.handleChange}
                      className="form-control"
                      id="email"
                      name="email"
                      value={data.email}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="pwd">{t("password")}</label>
                    <input
                      type="password"
                      onChange={this.handleChange}
                      className="form-control"
                      id="pwd"
                      name="password"
                      value={data.password}
                    />
                  </div>

                  <button
                    className="btn btn-danger auth-btn"
                    disabled={this.state.buttonDisable}
                  >
                    {this.state.loadingContent != null
                      ? this.state.loadingContent
                      : "Create Your Free Acount"}
                  </button>
                </form>

                {/* <button  className="btn btn-info"  onClick={this.testbtn}> Test </button> */}

                <p className="auth-link">
                  By creating an account you confirm that you’ve read,
                  understand and agree to our{" "}
                  <a
                    className="btn-link"
                    href="https://wrestlinglegendsnetwork.tv/page/terms"
                  >
                    Terms of Use
                  </a>{" "}
                  and{" "}
                  <a
                    className="btn-link"
                    href="https://wrestlinglegendsnetwork.tv/page/privacy"
                  >
                    Privacy Policy
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  paymentForm() {
    const { subscriptions } = this.state;
    var bgImg = {
      backgroundImage: `url(${configuration.get(
        "configData.common_bg_image"
      )})`,
    };
    const { data, couponeData } = this.state;
    const { t } = this.props;

    const { loadingPromoCode, promoCode, paymentMode, chosenPlan, isAnnual } =
      this.state;

    return (
      <div>
        <div className="common-bg-img" style={bgImg}>
          <div className="auth-page-header">
            <Link to={"/"}>
              <img
                src={configuration.get("configData.site_logo")}
                className="site-logo"
                alt="logo_img"
              />
            </Link>
          </div>
          <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-5 col-xl-4 auto-margin">
              <div className="register-box">
                <p className="steps">Step 2 of 2</p>
                <h3 className="register-box-head free_acount">
                  Activate your Account
                </h3>

                {/* plans */}

                {/* {alert(isAnnual)} */}
                {isAnnual ? (
                  <div className="row">
                    <PlanCard
                      plan={subscriptions[1]}
                      chosenPlan={this.SetChosenPlan}
                      active="true"
                    ></PlanCard>
                    <PlanCard
                      plan={subscriptions[0]}
                      chosenPlan={this.SetChosenPlan}
                      active="false"
                    ></PlanCard>
                  </div>
                ) : (
                  <div className="row">
                    <PlanCard
                      plan={subscriptions[1]}
                      chosenPlan={this.SetChosenPlan}
                      active="false"
                    ></PlanCard>
                    <PlanCard
                      plan={subscriptions[0]}
                      chosenPlan={this.SetChosenPlan}
                      active="true"
                    ></PlanCard>
                  </div>
                )}

                {/* <div className="register-box"> */}
                <div className="stripe-payment">
                  <div className="stripe-item ">
                    <a href="https://stripe.com/" target="_blank">
                      <img
                        src="assets/img/stripe.png"
                        className="stipe-logo"
                        alt="stripe"
                      />
                    </a>
                    <p className="payment-paragraph">Secure Information</p>
                  </div>
                  <div>
                    <ul className="card-list">
                      <li className="card-visa visa"></li>
                      <li className="card-mastercard visa"></li>
                      <li className="card-amex visa"></li>
                      {/* <li class="card-discover visa"></li> */}
                    </ul>
                  </div>
                </div>

                {/* <p className="auth-link">
                  By creating an account you confirm that you’ve read, understand and agree to our <a className="btn-link" href="https://wrestlinglegendsnetwork.tv/page/terms">Terms of Use</a> and <a className="btn-link" href="https://wrestlinglegendsnetwork.tv/page/privacy">Privacy Policy</a>.
                 
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderSubscription = (subscriptions) => {
    return (
      <React.Fragment>
        {subscriptions.map((subscription) => (
          <div
            className="col-sm-12 col-md-6 col-lg-6 col-xl-6"
            key={subscription.subscription_id}
          >
            <PlanCard plan={subscription}></PlanCard>
            {/* <PlanCard2></PlanCard2> */}
            {/* <div className="subcsription-card">
                            <div className="subcsription-head">
                                {subscription.title}
                            </div>
                            <div
                                className={
                                    "subcsription-price" +
                                    (subscription.popular_status == 1
                                        ? " premium"
                                        : "")
                                }
                            >
                                <p>plan</p>
                                <h4>
                                    {subscription.currency}
                                    {subscription.amount} / {subscription.plan}{" "}
                                    {translate("month")}
                                </h4>
                            </div>
                            <div className="subcsription-details">
                                <h4>maintain account</h4>
                                <h5>
                                    <i className="fas fa-user-plus" />
                                    {subscription.no_of_account}
                                </h5>
                                <p>{subscription.description}</p>
                                <div className="text-right mt-4">
                                    <Link
                                        to={{
                                            pathname: "/invoice",
                                            state: {
                                                subscription: subscription
                                            }
                                        }}
                                        className="btn btn-danger"
                                    >
                                        pay now
                                    </Link>
                                </div>
                            </div>
                        </div> */}
          </div>
        ))}
      </React.Fragment>
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({
      loadingContent: this.props.t("button_loading"),
      buttonDisable: true,
    });
    api
      .postMethod("v4/register", this.state.data)
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem("userId", response.data.data.user_id);
          localStorage.setItem("accessToken", response.data.data.token);
          localStorage.setItem("userType", response.data.data.user_type);
          localStorage.setItem("push_status", response.data.data.push_status);
          localStorage.setItem("username", response.data.data.name);
          localStorage.setItem(
            "active_profile_id",
            response.data.data.sub_profile_id
          );
          this.setState({ data: { nameOnCard: response.data.data.name } });
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({
            loadingContent: null,
            buttonDisable: false,
          });
          this.setState({ accessToken: true });
          // console.log("Yes");
          // window.location = state ? state.from.pathname : "/";
        } else {
          ToastDemo(
            this.props.toastManager,
            response.data.error_messages,
            "error"
          );
          this.setState({
            loadingContent: null,
            buttonDisable: false,
          });

          if (response.data.error_code == 3001) {
            setTimeout(function () {
              // window.location = "/login";
            }, 3000);
          }
        }
      })
      .catch((error) => {
        this.setState({ loadingContent: null, buttonDisable: false });
        ToastDemo(this.props.toastManager, error, "error");
      });
  };

  handleSubmitPayment = (event) => {};

  // COUPON fUNCTYIONS
  checkCoupon = (e) => {
    this.setState({ data: { coupon_code: e.target.value } });
  };

  handlePromoCode = (event) => {
    event.preventDefault();
    this.setState({
      loadingContent: translate("loading_text"),
    });
    let inputData = {
      coupon_code: this.state.data.coupon_code,
      subscription_id: this.state.chosenPlan.subscription_id,
      token: localStorage.getItem("accessToken")
        ? localStorage.getItem("accessToken")
        : "2y10Gv633zOPmq7IloFoF3HS5eRCLs3kqqeTjBfvNuU2XafLXApjW9Cy",
      id: localStorage.getItem("userId") ? localStorage.getItem("userId") : 1,
    };
    api
      .postMethod("apply/coupon/subscription", inputData)
      .then((response) => {
        if (response.data.success) {
          // ToastDemo(
          //   this.props.toastManager,
          //   translate("promo_code_applied_success"),
          //   "success"
          // );
          this.setState({
            loadingContent: null,
            buttonDisable: false,
            loadingPromoCode: false,
            promoCode: response.data.data,
          });

          this.setState({
            loadingContent: null,
            buttonDisable: false,
            pay_amount: response.data.data.remaining_amount,
          });
        } else {
          ToastDemo(
            this.props.toastManager,
            response.data.error_messages,
            "error"
          );
          this.setState({
            loadingContent: null,
            buttonDisable: false,
          });
        }
      })
      .catch((error) => {
        ToastDemo(this.props.toastManager, error, "error");
        this.setState({ loadingContent: null, buttonDisable: false });
      });
  };

  handlePromoCodeCancel = (event) => {
    event.preventDefault();
    this.setState({ promoCode: null, loadingPromoCode: true });
  };

  handlePromoCodeRemove = () => {
    this.setState({ promoCode: null, loadingPromoCode: true });
  };
  // Strip card

  addCard = async (ev) => {
    ev.preventDefault();

    if (!this.props.stripe || !this.props.elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // const stripe = this.props.stripe;

    // const payloadToken = await stripe.createToken(card, {
    //   name: this.state.data.nameOnCard
    // });

    // if (payloadToken.error) {
    //   console.log('[error]', payloadToken.error);
    //   this.setState({
    //     errorMessage: payloadToken.error.message,
    //     paymentMethod: null,
    //   });
    // } else {
    //   console.log(payloadToken);
    // }

    this.setState({
      loadingContent: translate("loading_text"),
      // buttonDisable: true,
    });
  };

  handlePayment = () => {
    this.setState({
      loadingContentCard: translate("loading_text"),
      buttonDisableCard: true,
    });
    let inputData;
    if (this.state.promoCode == null) {
      inputData = {
        subscription_id: this.state.chosenPlan.subscription_id,
        payment_mode: this.state.paymentMode,
      };
    } else {
      inputData = {
        coupon_code: this.state.data.coupon_code,

        subscription_id: this.state.chosenPlan.subscription_id,
        payment_mode: this.state.paymentMode,
      };
    }
    this.paymentApiCall(inputData);
  };

  paymentApiCall = (inputData) => {
    api
      .postMethod("v4/subscriptions_payment", inputData)
      .then((response) => {
        if (response.data.success) {
          // ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({
            loadingContentCard: null,
            buttonDisableCard: false,
          });
          this.setState({
            loadingContentCard: null,
            buttonDisableCard: false,
          });
          this.props.history.push("/view-profiles");
        } else {
          ToastDemo(
            this.props.toastManager,
            response.data.error_messages,
            "error"
          );
          this.setState({
            loadingContentCard: null,
            buttonDisableCard: false,
          });
        }
      })
      .catch((error) => {
        ToastDemo(this.props.toastManager, error, "error");
        this.setState({
          loadingContentCard: null,
          buttonDisableCard: false,
        });
      });
  };

  render() {
    return (
      <div>
        {this.state.accessToken === true
          ? this.paymentForm()
          : this.signupForm()}
      </div>
    );
  }
}

export default withRouter(withToastManager(translate(RegisterComponent)));
