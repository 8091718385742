import React from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
export default function Popup(props) {
  const { titleBtn, children, openPopup, setOpenPopup } = props;

  return (
    <Dialog open={openPopup}>
      <DialogTitle>
        <div>{titleBtn}</div>
      </DialogTitle>
      <DialogContent className="popUpSize">{children}</DialogContent>
    </Dialog>
  );
}
