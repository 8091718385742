import React, { useRef } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import api from "../../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import Helper from "../../Helper/helper";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import { Keyboard, Lazy } from "swiper";
import VoiceService from "../../Helper/VoiceService";

class Episodes extends Helper {
  state = {
    data: {
      value: "",
    },
    redirect: false,
    redirectPPV: false,
    redirectPaymentOption: false,
    videoDetailsFirst: null,
    playButtonClicked: false,
    loading: true,
    genreVideos: null,
    inputData: {
      admin_video_id: this.getMovieId(),
      skip: 0,
    },
    videoDetailsSecond: null,
    loadingSecond: true,

    episodes: null,
    btns: null,

    activeSlide: 0,
    isSwiperEp: true,
    swiper: null,
    startingEpisodes: true,
    handleKeyDown: this.handleKeyDown.bind(this),
  };

  sliderRef = (slider) => {
    this.slider = slider;
  };

  getMovieId() {
    let str = window.location.href;
    str = str.split("/");
    const vidID = str[str.length - 1];

    return vidID;
  }

  componentDidMount() {
    // window.location.reload(false);
    this.singleVideoSecond(this.state.inputData);
    this.setState({ playButtonClicked: false });

    window.addEventListener("keydown", this.state.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.state.handleKeyDown);
  }

  handleKeyDown(event) {

    console.log("Keydown Episode Page");
      if (
        localStorage.getItem("accessToken") &&
        window.location.href.indexOf("episode") > -1
      ) {
        if (this.state.startingEpisodes === true) {
          if (event.code === "Enter") {
            if (this.state.isSwiperEp === true) {
              const swiper = document.getElementsByClassName(
                "swiper-slide-active"
              );
              const movie = swiper[0].getElementsByTagName("a");
              //  console.log(moviePath);
              movie[0].click();
              this.setState({ startingEpisodes: false });
            } else {
              const btn = document.activeElement;
              btn.click();
            }
          }

          if (event.code === "Backspace") {
            this.setState({ startingEpisodes: false });
            const moviId = this.getMovieId();
            window.location = "/#/details/" + moviId;
          }

          if (event.code === "ArrowLeft") {
            this.setState({ isSwiperEp: false });

            let swiper = this.state.swiper;
            if (swiper === null) return;
            if (swiper.destroyed) return;
            if (typeof swiper === "undefined") return;
            if (swiper.keyboard) {
              swiper.keyboard.disable();
            }

            const swiperElement = document.getElementsByClassName(
              "swiper-slide-active"
            );

            const movie = swiperElement[0].getElementsByTagName("a");
            const poster = movie[0].getElementsByTagName("img");
            poster[0].classList.add("btn_noFocus");

            const focusBtns = document.getElementsByClassName("episode_btn");
            focusBtns[0].tabIndex = 0;
            focusBtns[0].focus();
            VoiceService(focusBtns[0].textContent);
          }

          if (event.code === "ArrowRight") {
            this.setState({ isSwiperEp: true });

            let swiper = this.state.swiper;
            if (swiper === null) return;
            if (swiper.destroyed) return;
            if (typeof swiper === "undefined") return;
            if (swiper.keyboard) {
              swiper.keyboard.enable();
            }

            const swiperElement = document.getElementsByClassName(
              "swiper-slide-active"
            );

            const movie = swiperElement[0].getElementsByTagName("a");
            const poster = movie[0].getElementsByTagName("img");
            poster[0].classList.remove("btn_noFocus");
            VoiceService(swiperElement[0].innerText); 
          }

          if (event.code === "ArrowDown" && !this.state.isSwiperEp) {
            const focusBtns = document.getElementsByClassName("episode_btn");
            for (let i = 0; i < focusBtns.length - 1; i++) {
              if (focusBtns[i] === document.activeElement) {
                focusBtns[i + 1].tabIndex = 0;
                focusBtns[i + 1].focus();
                VoiceService(focusBtns[i+1].textContent);
                return;
              }
            }
          }

          if (event.code === "ArrowUp" && !this.state.isSwiperEp) {
            const focusBtns = document.getElementsByClassName("episode_btn");
            for (let i = 1; i < focusBtns.length; i++) {
              if (focusBtns[i] === document.activeElement) {
                focusBtns[i - 1].tabIndex = 0;
                focusBtns[i - 1].focus();
                VoiceService(focusBtns[i-1].textContent);
                return;
              }
            }
          }
        }
      }
    }

  singleVideoSecond(inputData) {
    api
      .postMethod("videos/view/second", inputData)
      .then((response) => {
        if (response.data.success === true) {
          let videoDetailsSecond = response.data.data;

          this.setState({
            loadingSecond: false,
            episodes: videoDetailsSecond.genre_videos,
            btns: videoDetailsSecond.genres.reverse(),
          });
        } else {
        }
      })
      .catch(function (error) {});
  }

  handleGenre = (genre_id) => {
    let inputData = {
      ...this.state.inputData,
      genre_id: genre_id,
      skip: 0,
    };
    // const data = { ...this.state.data };
    // data[name] = genre_id;
    // this.setState({ data });

    api
      .postMethod("genre_videos", inputData)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            loading: false,
            genreVideos: response.data.data,
          });
        } else {
          // Do nothing
        }
      })
      .catch(function (error) {});
  };

  handlePlayVideo = async (event, admin_video_id) => {
    event.preventDefault();

    let inputData = {
      ...this.state.inputData,
      admin_video_id: admin_video_id,
    };

    await this.onlySingleVideoFirst(inputData);

    if (this.state.videoDetailsFirst.success === false) {
      ToastDemo(
        this.props.toastManager,
        this.state.videoDetailsFirst.error_messages,
        "error"
      );
    } else {
      this.redirectStatus(this.state.videoDetailsFirst);
      this.setState({ playButtonClicked: true });
    }
  };

  render() {
    if (this.state.playButtonClicked) {
      const returnToVideo = this.renderRedirectPage(
        this.state.videoDetailsFirst
      );

      if (returnToVideo != null) {
        return returnToVideo;
      }
    }
    let genreVideos;
    // const { genres } = this.state.videoDetailsSecond.genres;
    const { loading, loadingSecond, episodeSlider } = this.state;
    if (loading) {
      if (!loadingSecond) {
        genreVideos = this.state.episodes;
      }
    } else {
      genreVideos = this.state.genreVideos;
    }

    return (
      <div>
        {loadingSecond ? (
          <div></div>
        ) : (
          <div className="row">
            <div className="col-4 eps_btn">
              {this.state.btns.map((genre) => (
                <div
                  className="btn btn-outline-secondary btn-right-space br-0 episode_btn"
                  onClick={() => this.handleGenre(genre.genre_id)}
                >
                  {genre.genre_name}
                </div>
              ))}
            </div>
            <div className="col-8 p-5">
              <Swiper
                className={"ep_swiper"}
                direction={"vertical"}
                spaceBetween={10}
                slidesPerView={1}
                lazy={true}
                height={200}
                width={30}
                keyboard={{ enabled: true }}
                modules={[Keyboard, Lazy]}
                onSlideChange={(swiper) => {
                  if (swiper.realIndex >= 0 && swiper.wrapperEl.children.length > swiper.realIndex) {
                    VoiceService(swiper.wrapperEl.children[swiper.realIndex].firstChild.innerText);
                   }
                }}
                onSwiper={(swiper) => {
                  this.setState({ swiper: swiper });
                  if (swiper.realIndex >= 0 && swiper.wrapperEl.children.length > swiper.realIndex) {
                    VoiceService(swiper.wrapperEl.children[swiper.realIndex].firstChild.innerText);
                   }
                }}
              >
                {genreVideos.map((video) => (
                  <SwiperSlide className="ep-slider">
                    <div key={video.admin_video_id}>
                      <div className="row">
                        <div className="col-4">
                          <div className="relative">
                            <Link
                              to="#"
                              onClick={(event) =>
                                this.handlePlayVideo(
                                  event,
                                  video.admin_video_id
                                )
                              }
                            >
                              <img
                                className="trailers-img placeholder"
                                alt="episode-img"
                                src={video.default_image}
                                data-src="assets/img/thumb1.jpg"
                                srcSet={
                                  video.default_image +
                                  " 1x," +
                                  video.default_image +
                                  " 1.5x," +
                                  video.default_image +
                                  " 2x"
                                }
                                data-srcset="assets/img/thumb1.jpg 1x,
                            assets/img/thumb1.jpg 1.5x,
                            assets/img/thumb1.jpg 2x"
                              />
                              <div className="trailers-img-overlay"></div>
                            </Link>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="episode-content">
                            <div className="row">
                              <div className="col-xl-8 col-lg-8">
                                <h4 className="episode-content-head">
                                  {video.title}
                                </h4>
                              </div>
                              <div className="col-xl-4 col-lg-4">
                                <h4 className="episode-content-head text-right">
                                  {video.duration}
                                </h4>
                              </div>
                            </div>
                            <h4 className="episode-content-desc">
                              {video.description}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withToastManager(Episodes);
