import React from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import Helper from "../../Helper/helper";
import ContentLoader from "../../Static/contentLoader";

import api from "../../../Environment";

import io from "socket.io-client";

import { apiConstants } from "../../Constant/constants";
import Controls from "./Controls";
import VoiceService from "../../Helper/VoiceService";

// const socket = apiConstants.socketUrl
//   ? io(apiConstants.socketUrl, {
//       secure: true,
//       reconnect: true,
//       rejectUnauthorized: false,
//     })
//   : "";
let userId = localStorage.getItem("userId");

let accessToken = localStorage.getItem("accessToken");

let count = 0;
const seekOffset = 10;

class VideoComponent extends Helper {
  state = {
    loadingFirst: true,
    videoDetailsFirst: null,
    onPlayStarted: false,
    videoList: {},
    videoData: null,
    videoId: 0,
    socket: false,
    query: "",
    onSeekPlay: true,
    socketConnection: true,
    videoDuration: 0,
    socketConnected: false,
    // player controler variables
    playing: true,
    played: 0,
    setFocuse: true,
    admin_video_id: this.getMovieId(),
    handleKeyDown: this.handleKeyDown.bind(this),
  };

  setFocusedItem = (nextItem) => {
    nextItem[0].tabIndex = 0;
    nextItem[0].focus();
    var buttonContent = nextItem[0].getAttribute("aria-label");
    if (buttonContent === "play") {
      buttonContent = this.state.playing ? "pause" : "play";
    }
    VoiceService(buttonContent + " button");
  };

  getMovieId() {
    let str = window.location.href;
    str = str.split("/");
    const vidID = str[str.length - 1];

    return vidID;
  }

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({ loadingFirst: false });
    } else {
      window.location = "/home";
    }

    // this.controls.style.visibility = "hidden";

    window.addEventListener("keydown", this.state.handleKeyDown);
  }

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    let videoId = this.props.location.state.videoDetailsFirst.admin_video_id;
    let videoData = [
      {
        sub_profile_id: localStorage.getItem("active_profile_id"),
        admin_video_id: videoId,
        id: userId,
        token: accessToken,
        duration: this.state.videoDuration,
      },
    ];

    api
      .postMethod("save/watching/video", videoData[0])
      .then((response) => {
        if (response.data.success === true) {
        } else {
        }
      })
      .catch(function (error) {});

    clearInterval(this.state.intervalId);
    window.removeEventListener("keydown", this.state.handleKeyDown);
  }

  handleKeyDown(event) {

    console.log("Keydown Video Page");
      const cPlayBtn = document.getElementsByClassName("control_play_btn");
      const rewinfBtn = document.getElementsByClassName("control_rewind_btn");
      const fastforwardBtn = document.getElementsByClassName(
        "control_fastforward_btn"
      );

      if (
        localStorage.getItem("accessToken") &&
        window.location.href.indexOf("video") > -1
      ) {
        count = 0;

        if (this.controls.style.visibility === "hidden") {
          this.controls.style.visibility = "visible";
          count = 0;
          setTimeout(()=> {
            this.setFocusedItem(cPlayBtn);
          }, 0);
        } else {
          switch (event.keyCode) {
            case 8:  //  Backspace
              window.location = `/#/details/${this.getMovieId()}`;
              break;
            case 39: // ArrowRight
              if (cPlayBtn[0] === document.activeElement) {
                this.setFocusedItem(fastforwardBtn);
              }
              if (rewinfBtn[0] === document.activeElement) {
                this.setFocusedItem(cPlayBtn);
              }
              break;
            case 37: // ArrowLeft
              if (cPlayBtn[0] === document.activeElement) {
                this.setFocusedItem(rewinfBtn);
              }
              if (fastforwardBtn[0] === document.activeElement) {
                this.setFocusedItem(cPlayBtn);
              }
              break;
            case 13: // MediaPlayPause
              this.handlePlayPause();
              break;
            case 417: //  MediaFastForward
              this.handleFastForward();
              break;
            case 412: //  MediaRewind
              this.handleRewind();
              break;
            default:
              if (fastforwardBtn[0] === document.activeElement) {
              } else if (cPlayBtn[0] === document.activeElement) {
              } else if (rewinfBtn[0] === document.activeElement) {
              } else {
                this.setFocusedItem(cPlayBtn);
              }
      
                break;
          }
        }
      }
    }

  handlePlayPause = () => {
    VoiceService(this.state.playing ? "Paused" : "Play");
    this.setState({ playing: !this.state.playing });
  };

  handleRewind = () => {
    VoiceService("Rewind " + seekOffset + " seconds");
    this.player.seekTo(this.player.getCurrentTime() - seekOffset);
  };

  handleFastForward = () => {
    VoiceService("Fast forward " + seekOffset + " seconds");
    this.player.seekTo(this.player.getCurrentTime() + seekOffset);
  };

  onCompleteVideo = () => {
    this.addHistory(this.props.location.state.videoDetailsFirst.admin_video_id);
    this.setState({ onPlayStarted: false, socketConnection: false });
  };

  onVideoPlay = async () => {
    let intervalId = setInterval(this.timer, 3000);

    this.setState({ intervalId: intervalId });

    this.setState({ onPlayStarted: true, socketConnection: true });

    let inputData = {
      admin_video_id:
        this.props.location.state.videoDetailsFirst.admin_video_id,
    };
    await this.onlySingleVideoFirst(inputData);

    this.redirectStatus(this.state.videoDetailsFirst);

    const seekTime = this.state.videoDetailsFirst.seek_time_in_seconds
      ? this.state.videoDetailsFirst.seek_time_in_seconds
      : 0;

    if (this.state.onSeekPlay) {
      this.player.seekTo(parseFloat(seekTime));
    }

    this.setState({ onSeekPlay: false });
  };

  addHistory = (admin_video_id) => {
    // api
    //   .postMethod("addHistory", { admin_video_id: admin_video_id })
    //   .then((response) => {
    //     if (response.data.success === true) {
    //     } else {
    //     }
    //   })
    //   .catch(function (error) {});
  };

  onPauseVideo = async () => {
    if (this.state.socketConnected) {
    }
    clearInterval(this.state.intervalId);
  };

  onVideoTimeUpdate = (duration) => {
    // console.log(duration.target.currentTime);
    let video_duration = duration.target.currentTime;

    let sec = parseInt(video_duration % 60);
    let min = parseInt((video_duration / 60) % 60);
    let hours = parseInt(video_duration / 3600);

    if (hours > 1) {
      this.setState({ videoDuration: hours + ":" + min + ":" + sec });
    } else {
      this.setState({ videoDuration: min + ":" + sec });
    }
  };

  ref = (player) => {
    this.player = player;
  };

  refControls = (controls) => {
    this.controls = controls;
  };

  convert(srtSubtitle) {
    var webvtt;
    if (srtSubtitle !== undefined) {
      webvtt = srtSubtitle.substr(0, srtSubtitle.lastIndexOf(".")) + ".vtt";
    } else {
      webvtt = srtSubtitle;
    }
    return webvtt;
  }

  format = (seconds) => {
    if (isNaN(seconds)) {
      return "00:00";
    }

    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");

    if (hh) {
      return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    }

    return `${mm}:${ss}`;
  };

  render() {
    const pageType = "videoPage";
    if (this.state.onPlayStarted) {
      const returnToVideo = this.renderRedirectPage(
        this.state.videoDetailsFirst,
        pageType
      );

      if (returnToVideo != null) {
        return returnToVideo;
      }
    }
    const { loadingFirst, playing, played } = this.state;
    let mainVideo, videoTitle, videoType, subTitle;

    if (loadingFirst) {
      return <ContentLoader />;
    } else {
      // Check the whether we need to play the trailer or main video

      if (this.props.location.state.videoFrom !== undefined) {
        subTitle = this.convert(
          this.props.location.state.videoDetailsFirst.video_subtitle
        );

        if (this.props.location.state.videoFrom === "trailer") {
          mainVideo =
            this.props.location.state.videoDetailsFirst.resolutions.original;
          if (
            this.props.location.state.videoDetailsFirst.trailer_subtitle !==
            undefined
          ) {
            subTitle = this.convert(
              this.props.location.state.videoDetailsFirst.trailer_subtitle
            );
          }
        } else {
          mainVideo =
            this.props.location.state.videoDetailsFirst.resolutions.original;
        }

        videoTitle = this.props.location.state.videoDetailsFirst.name;

        videoType = this.props.location.state.videoDetailsFirst.video_type;
      } else {
        if (this.props.location.state.videoDetailsFirst.main_video) {
        mainVideo = this.props.location.state.videoDetailsFirst.main_video;
        } else {
          mainVideo = this.props.location.state.videoDetailsFirst.trailer_video;
        }

        subTitle = this.convert(
          this.props.location.state.videoDetailsFirst.video_subtitle
        );

        videoTitle = this.props.location.state.videoDetailsFirst.title;

        videoType = this.props.location.state.videoDetailsFirst.video_type;
      }

      const handleProgress = (changeState) => {
        if (count > 3) {
          this.controls.style.visibility = "hidden";
          count = 0;
        } else {
          count += 1;
        }

        this.setState({ ...this.state, ...changeState });
      };

      const currentTime = this.player ? this.player.getCurrentTime() : "00:00";
      const duration = this.player ? this.player.getDuration() : "00:00";
      const elapsedTime = this.format(currentTime);
      const totalTime = this.format(duration);

      return (
        <div>
          <div className="single-video">
            <ReactPlayer
              ref={this.ref}
              url={mainVideo}
              controls={false}
              width="100%"
              height="100vh"
              muted={false}
              playing={playing}
              onProgress={handleProgress}
              onStart={this.onLoad}
              onPause={this.onPauseVideo}
              onPlay={
                this.props.location.state.videoFrom === "trailer"
                  ? ""
                  : this.onVideoPlay
              }
              onEnded={this.onCompleteVideo}
              onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
              config={{
                file: {
                  tracks: [
                    {
                      kind: "subtitles",
                      src: subTitle,
                      srcLang: "en",
                      default: true,
                    },
                  ],
                  attributes: {
                    crossOrigin: "true",
                    controlsList: "nodownload",
                  },
                },
              }}
            />
            <Controls
              ref={this.refControls}
              onPlayPause={this.handlePlayPause}
              playing={playing}
              videoTitle={videoTitle}
              onRewind={this.handleRewind}
              onFastForward={this.handleFastForward}
              elapsedTime={elapsedTime}
              totalDuration={totalTime}
              played={played}
            ></Controls>
            <div className="back-arrowsec">
              {/* <Link to="/home">
                <img
                  src={window.location.origin + "/assets/img/left-arrow.png"}
                  alt="arrow"
                />
                {videoType === 2 ? (
                  ""
                ) : (
                  <span className="txt-overflow capitalize ml-3">
                    {videoTitle}
                  </span>
                )}
              </Link> */}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default VideoComponent;
